import { createState } from '@hookstate/core';

const chartStore = createState({
	img: '',
	width: 0,
	height: 0,
	isLoaded: false,
});

export default chartStore;
