import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Row,
	Col,
	Nav,
	Image,
	Navbar,
	Container,
	ListGroup,
	Button,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Routes } from '../../routes';
import NOTIFICATIONS_DATA from '../../data/notifications';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AuthenticationRequests } from '../../requests/index';
import jwtDecode from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const PublicNavbar = (props) => {
	const { iconTitle, title } = props;

	const [notifications, setNotifications] = useState(NOTIFICATIONS_DATA);
	const areNotificationsRead = notifications.reduce(
		(acc, notif) => acc && notif.read,
		true
	);
	const history = useHistory();
	const dispatch = useDispatch();
	const { t } = useTranslation(['signin', 'common']);

	const decoded = localStorage.getItem('token')
		? jwtDecode(localStorage.getItem('token'))
		: null;

	const markNotificationsAsRead = () => {
		setTimeout(() => {
			setNotifications(notifications.map((n) => ({ ...n, read: true })));
		}, 300);
	};

	const signOut = () => {
		dispatch(
			AuthenticationRequests.signOut((response) => {
				history.push(Routes.Signin.path);
			})
		);
	};

	const Notification = (props) => {
		const { link, sender, image, time, message, read = false } = props;
		const readClassName = read ? '' : 'text-danger';

		return (
			<ListGroup.Item action href={link} className="border-bottom border-light">
				<Row className="align-items-center">
					<Col className="col-auto">
						<Image
							src={image}
							className="user-avatar lg-avatar rounded-circle"
						/>
					</Col>
					<Col className="ps-0 ms--2">
						<div className="d-flex justify-content-between align-items-center">
							<div>
								<h4 className="h6 mb-0 text-small">{sender}</h4>
							</div>
							<div className="text-end">
								<small className={readClassName}>{time}</small>
							</div>
						</div>
						<p className="font-small mt-1 mb-0">{message}</p>
					</Col>
				</Row>
			</ListGroup.Item>
		);
	};

	return (
		<Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
			<Container fluid className="px-0">
				<div className="d-flex justify-content-between w-100">
					<div className="d-flex align-items-center">
						<span className="icon icon-sm mb-2 px-2">
							<FontAwesomeIcon icon={iconTitle} />
						</span>
						<h4 className="ml-10">{title}</h4>
					</div>
					<Nav
						className="align-items-center"
						style={{ justifyContent: 'center', alignItems: 'center' }}
					>
						<a
							style={{
								marginRight: 10,
								justifyContent: 'center',
								display: 'flex',
								marginBottom: 12,
							}}
							href="https://dashboard.appar.io/signup"
						>
							{t('signup')}
						</a>
						<Button className="mb-3" as={Link} to={Routes.Signin.path}>
							{t('signin')}
						</Button>
					</Nav>
				</div>
			</Container>
		</Navbar>
	);
};

PublicNavbar.propTypes = {
	iconTitle: PropTypes.any,
	title: PropTypes.string,
};

export default PublicNavbar;
