import axios from 'axios';
import { RequestCallback } from 'typings/custom';

export const startFreeTrial = (callback: RequestCallback) => async () => {
	await axios
		.get('/payment/freetrial')
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};

export const getPayments = (callback: RequestCallback) => async () => {
	await axios
		.get('/payment')
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};

export const createPayment =
	(subscription_id: ID, callaback: RequestCallback) => async () => {
		await axios
			.post('/payment', { subscription_id: subscription_id })
			.then((response) => {
				callaback(response);
			})
			.catch((error) => {
				callaback(error.response);
			});
	};

export const getProductState = (callback: RequestCallback) => async () => {
	await axios
		.get('/payment/products')
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};

export const getPrices =
	(currency = 'clp', callback: RequestCallback) =>
	async () => {
		await axios
			.get(`/payment/prices?currency=${currency}`)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};
