import axios from 'axios';
import { RequestCallback, Variant } from 'typings/custom';

export const getVariants = (product_id: ID, callback: RequestCallback) => async () => {
	await axios
		.get(`/product/${product_id}/variants`)
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};

export const updateVariant = (variant: Variant, callback: RequestCallback) => async () => {
	await axios
		.put(`/variant/${variant.variant_id}`, variant)
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};

export const createVariant = (product_id: ID, callback: RequestCallback) => async () => {
	await axios
		.post('/variant/', { product_id: product_id })
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};
