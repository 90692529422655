import axios from 'axios';

export const getSkus = async (provider: string) => {
	let data: any;
	let error: string | null = null;
	await axios
		.get(`${process.env.REACT_APP_APPAR_PROVIDER_API}sku/${provider}`)
		.then((response) => {
			data = response.data;
		})
		.catch((e) => {
			error = e.response.data;
		});

	return { data, error };
};

export const deleteSku = async (provider: string, sku: ID) => {
	let data: any;
	let error: string | null = null;
	await axios
		.delete(`${process.env.REACT_APP_APPAR_PROVIDER_API}sku/${provider}/${sku}`)
		.then((response) => {
			data = response.data;
		})
		.catch((e) => {
			error = e.response.data;
		});

	return { data, error };
};

export const updateSku = async (provider: string, sku: ID, product: any) => {
	let data: any;
	let error: string | null = null;
	await axios
		.put(`${process.env.REACT_APP_APPAR_PROVIDER_API}sku/${provider}/${sku}`, product)
		.then((response) => {
			data = response.data;
		})
		.catch((e) => {
			error = e.response.data;
		});

	return { data, error };
};
