import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'react-bootstrap';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import '../style.css';

/**
 * @callback   onChange     Trigged when the dropdown change value, return the selected object
 *
 * @param      {Array}      data            	Array of object with the data for create the dropdown, each object require a 'name' value
 * @param      {string}     noDataMessage   	Message for display when the data array has a length of 0
 * @param      {string}     nullItem        	Create a item as first element on the dropdown with the this value as name. Return null value when selected
 * @param      {bool}       nullItemDefault 	If true the selected value of the dropdown will be the nullItem otherwise will be the first object of the data array
 * @param      {int}        defaultIndex    	Set the data array element with this index as selected (Using array.findIndex is recommended). Values less than 0 will be converted to 0
 * @param      {bool}       disabled        	If true will block the deploy of the items
 * @param      {string}     disabledMessage 	Message to show on the Dropdown when is disabled
 * @param      {faIcon}     faIcon          	Put a textFontawesome icon before the title
 * @param      {string}     sessionElementKey	Session key to store a selected element index in session. During that session the selected element will persist beteween views.
 */

const DropDown = (props) => {
	const {
		data,
		defaultIndex,
		disabled,
		disabledMessage,
		faIcon,
		noDataMessage,
		nullItem,
		nullItemDefault,
		onChange,
		sessionElementKey,
	} = props;

	const [selectedItem, setSelectedItem] = useState(null);

	useEffect(() => {
		if (!sessionStorage.getItem(sessionElementKey)) {
			sessionStorage.setItem(sessionElementKey, 0);
		}
		if (sessionElementKey && sessionElementKey !== '') {
			setSelectedItem(data[sessionStorage.getItem(sessionElementKey)]);
			onChange(data[sessionStorage.getItem(sessionElementKey)]);
		} else if (!nullItemDefault) {
			const startingIndex = defaultIndex >= 0 ? defaultIndex : 0;
			setSelectedItem(data[startingIndex]);
			onChange(data[startingIndex]);
		}
	}, [data]);

	const generateItems = () => {
		let generatedItems = [];

		if (nullItem) {
			if (nullItemDefault && selectedItem == null) {
				setSelectedItem({ name: nullItem });
			}

			generatedItems.push(
				<Dropdown.Item
					key={0}
					onClick={() => {
						setSelectedItem({ name: nullItem });
						onChange(null);
					}}
				>
					{nullItem}
				</Dropdown.Item>
			);
		}

		data.map((item, index) => {
			generatedItems.push(
				<Dropdown.Item
					key={index + 1}
					onClick={() => {
						setSelectedItem(item);
						onChange(item);
						sessionStorage.setItem(sessionElementKey, data.indexOf(item));
					}}
				>
					{item.name}
				</Dropdown.Item>
			);
		});

		return generatedItems;
	};

	return (
		<Dropdown>
			<Dropdown.Toggle
				style={{
					backgroundColor: '#fff',
					color: '#65AF1A',
					borderColor: '#65AF1A',
					boxShadow: '1px 2px 1px 1px rgba(0, 0, 0, 0.2)',
				}}
				variant="primary"
				disabled={disabled}
			>
				{faIcon && <FontAwesomeIcon icon={faIcon} style={{ marginRight: 5 }} />}{' '}
				{disabled
					? disabledMessage
					: selectedItem
					? selectedItem.name
					: noDataMessage}
				<span className="icon icon-small ms-1 ml-2">
					<FontAwesomeIcon icon={faChevronDown} style={{ marginLeft: 5 }} />
				</span>
			</Dropdown.Toggle>
			<Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-1 dropdown-size">
				{generateItems()}
			</Dropdown.Menu>
		</Dropdown>
	);
};

DropDown.propTypes = {
	data: PropTypes.arrayOf(PropTypes.object).isRequired,
	defaultIndex: PropTypes.number,
	disable: PropTypes.bool,
	disabledMessage: PropTypes.string,
	faIcon: PropTypes.any,
	noDataMessage: PropTypes.string,
	nullItem: PropTypes.string,
	nullItemDefault: PropTypes.bool,
	onChange: PropTypes.func,
	sessionElementKey: PropTypes.string,
};

DropDown.defaultProps = {
	data: [],
	defaultIndex: 0,
	disabled: false,
	disabledMessage: 'Disabled',
	noDataMessage: 'No data provided',
	nullItemDefault: false,
	onChange: () => {},
	sessionElementKey: '',
};

export default DropDown;
