import { Button, Spinner } from 'react-bootstrap';
import React, { useRef, useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { processDataForCsv } from '../../utils/components';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Header } from './ExportCsv.types';
import { toast } from 'react-toastify';

interface Props {
	augmentations: Array<object>,
	dates: object,
	fileName: string
	headers: Array<Header>,
	visits: Array<object>,
	title: string,
}

const ExportCsv =
	({
		headers,
		fileName,
		visits,
		augmentations,
		dates,
		title,
	}: Props) => {
		const { t } = useTranslation('components');
		const csvLinkRef = useRef<{ link: HTMLAnchorElement }>(null);
		const [loading, setLoading] = useState(false)
		const [content, setContent] = useState<any[]>([])

		useEffect(() => {
			if (content.length > 0) {
				csvLinkRef?.current?.link.click()
			}
			setLoading(false)
		}, [content])

		return (
			<>
				<Button
					variant="secondary"
					onClick={async (e) => {
						setLoading(true)
						await processDataForCsv(visits, augmentations, dates, (result: any[]) => {

							if (result.length > 0) {
								setContent(result)
							} else {
								toast.error(t('export.error_exporting_data'))
								setLoading(false)
							}
						})
					}}
					title={title}
				>
					{!loading &&
						<>
							<FontAwesomeIcon
								icon={faFileCsv as IconProp}
								className="me-1 "
								style={{ marginRight: 5 }}
							/>
							{t(`export.csv`)}
						</>
					}
					{loading &&
						<Spinner animation="border" role="status" size='sm' />
					}
				</Button>

				<CSVLink
					headers={headers}
					filename={`${fileName}.csv`}
					data={content}
					ref={csvLinkRef}
					separator={";"}
				/>
			</>
		);
	};

ExportCsv.propTypes = {
	fileName: PropTypes.string,
	headers: PropTypes.arrayOf(PropTypes.shape({
		label: PropTypes.string,
		key: PropTypes.string
	})),
	title: PropTypes.string,
};

ExportCsv.defaultProps = {
	fileName: 'report',
	title: 'csv',
};

export default ExportCsv;
