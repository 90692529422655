import axios from 'axios';
import { RequestCallback, User } from 'typings/custom';

export const model = (user: User, callback: RequestCallback) => async () => {
	await axios
		.post('/contact/model', user)
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};

export const product =
	(request: object, callback: RequestCallback) => async () => {
		await axios
			.post('/contact/product', request)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const support =
	(form: object, callback: RequestCallback) => async () => {
		await axios
			.post('/contact/support', form)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const supportTypes = (callback: RequestCallback) => async () => {
	await axios
		.get('/contact/support')
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};
