export const getHitPoint = (viewer, event) => {
	let rect = viewer.getBoundingClientRect();

	const x = event.clientX - rect.left;
	const y = event.clientY - rect.top;
	return viewer.positionAndNormalFromPoint(x, y);
};

export const createHotspot = (
	identifier,
	position,
	normal,
	className = 'hotspot'
) => {
	const hotspot = document.createElement('button');
	hotspot.slot = 'hotspot-' + identifier;
	hotspot.classList.add(className);
	hotspot.id = identifier;
	hotspot.dataset.position = position.toString();
	hotspot.dataset.normal = normal.toString();
	return hotspot;
};

export const createAnnotation = (
	identifier,
	hotspot,
	defaultMessage = 'Default Message'
) => {
	const annotation = document.createElement('div');
	annotation.classList.add('annotation');
	annotation.id = 'annotation-' + identifier;
	annotation.appendChild(document.createTextNode(defaultMessage));
	hotspot.appendChild(annotation);
	document.querySelector('input').value = '';

	return annotation;
};
