import React, { useState } from 'react'
import moment from 'moment-timezone'
import { Button, OverlayTrigger, Popover } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons'
import { DateRange } from 'react-date-range'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as locales from 'react-date-range/dist/locale'

const DatePicker = (props) => {
	const currentYear = moment().get('year')
	const { t } = useTranslation(['common', 'date', 'components'])
	const { onDateChange } = props

	const [locale, setLocale] = useState('es')

	const [dates, setDates] = useState([
		{
			startDate: moment().startOf('month').toDate(),
			endDate: moment().toDate(),
			key: 'selection',
		},
	]);

	const popover = (
		<Popover id="popover-basic" style={{ maxWidth: 'none' }}>
			<Popover.Body>
				<DateRange
					locale={locales[locale]}
					editableDateInputs={true}
					onChange={(item) => {
						setDates([item.selection])
						if(onDateChange){
							onDateChange({from:moment(item.selection.startDate), to:moment(item.selection.endDate)})
						}
					}}
					moveRangeOnFirstSelection={false}
					ranges={dates}
					maxDate={moment().toDate()}
					showMonthAndYearPickers={true}
				/>
			</Popover.Body>
		</Popover>
	)

	return (
		<OverlayTrigger
			rootClose
			trigger="click"
			placement="bottom"
			overlay={popover}
			className="bg-light d-block">
			<Button style={{ maxWidth: "190px" }} className="btn-date">
				<FontAwesomeIcon icon={faCalendarDays} style={{ fontSize: 20, color: '#5C499F', marginRight: '8px' }}/>
				{t('common:select_date')}
			</Button>
		</OverlayTrigger>
	)
}

export default DatePicker
