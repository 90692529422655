import { useEffect, useState } from 'react';
import { Button, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { SimplifiedModal } from '..';
import { Routes } from '../../routes';

const Copilot = () => {
	const { t } = useTranslation(['components', 'common', 'store']);
	const history = useHistory();

	const [message, setMessage] = useState('');
	const [action, setAction] = useState(null);

	const [open, setOpen] = useState(false);

	useEffect(() => {
		checkCode();
	}, [localStorage.getItem('copilot_code')]);

	const checkCode = () => {
		const code = localStorage.getItem('copilot_code');
		setOpen(code != null);
		setMessage(t('copilot.' + code));

		switch (code) {
			case 'link_without_signin':
				setAction(
					<div style={{ textAlign: 'center' }}>
						<Button
							variant="secondary"
							onClick={() => history.push(Routes.Store.path)}
							style={{ marginRight: '10px' }}
						>
							{t('common:store')}
						</Button>
						<Button
							variant="secondary"
							onClick={() => history.push(Routes.Store.path)}
						>
							{t('common:integrations')}
						</Button>
					</div>
				);
				break;
			case 'link_without_store':
				if (history.location.pathname === Routes.Store.path) {
					setOpen(false);
				}

				setAction(
					<div style={{ textAlign: 'center' }}>
						<Button
							variant="secondary"
							onClick={() => history.push(Routes.Store.path)}
						>
							{t('store:create_new_store')}
						</Button>
					</div>
				);
				break;
			case 'link_with_store_created':
				setMessage(t('copilot.link_with_store_created'));
				setAction(
					<div style={{ textAlign: 'center' }}>
						<Image
							src="/images/integrations/tiendanube-640w.webp"
							onClick={() =>
								(window.location.href =
									'https://www.tiendanube.com/apps/3605/authorize')
							}
							style={{
								cursor: 'pointer',
								height: 80,
								borderStyle: 'solid',
								borderRadius: 10,
								borderWidth: 2,
							}}
						/>
					</div>
				);
				break;
		}
	};

	const Footer = () => (
		<>
			<Button
				variant="default"
				onClick={() => {
					localStorage.removeItem('copilot_code');
					setOpen(false);
				}}
			>
				{t('copilot.dont_remind_me')}
			</Button>
			<Button
				variant="default"
				onClick={() => {
					setOpen(false);
				}}
			>
				{t('copilot.later')}
			</Button>
		</>
	);

	return (
		<>
			<SimplifiedModal
				open={open}
				onHide={() => setOpen(false)}
				footerContent={<Footer />}
			>
				<p>{message}</p>
				{action}
			</SimplifiedModal>
		</>
	);
};

export default Copilot;
