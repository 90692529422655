import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

interface Props {
	active: boolean
}
const Backdrop = (props: Props) => {

	const { active } = props;

	const { t } = useTranslation('common')

	return (
		<>
			{active && (
				<div className="backdrop-loader">
					<div>
						<Spinner
							className="loader-spinner"
							style={{ marginLeft: '26%' }}
							animation="border"
							role="status"
						>
							<span className="sr-only">{`${t('loading')}...`}</span>
						</Spinner>
						<p className="loader-spinner"></p>
					</div>
				</div>
			)}
		</>
	);
};

Backdrop.propTypes = {
	active: PropTypes.bool.isRequired,
};

Backdrop.defaultProps = {
	active: false,
};

export default Backdrop;
