import { useEffect, useState } from 'react';
import SimplifiedCard from '../SimplifiedCard';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const MaterialEditor = (props) => {
	const {
		editedMaterials,
		onMaterialChange,
		onIDColorChange,
		defaultIDColor,
		onResetMaterials,
		onDeleteVariant,
	} = props;
	const { t } = useTranslation(['product', 'common']);

	const [viewer, setViewer] = useState(null);
	const [modelLoaded, setModelLoaded] = useState(false);
	const [selectedMaterial, setSelectedMaterial] = useState({
		index: 0,
		color: '#ffffff',
	});

	const [materials, setMaterials] = useState([]);
	const [originalMaterials, setOriginalMaterials] = useState([]);
	const [IDColor, setIDColor] = useState(defaultIDColor);

	useEffect(() => {
		const viewerTemp = document.getElementById('model-viewer');

		viewerTemp.addEventListener('load', () => {
			setModelLoaded(true);
			setOriginalMaterials([]);
			loadMaterials(viewerTemp);
			setIDColor(defaultIDColor);
		});

		setViewer(viewerTemp);
	}, []);

	useEffect(() => {
		if (modelLoaded) {
			loadMaterials(viewer);
			changeSelectedMaterial(0);
		}
	}, [editedMaterials]);

	useEffect(() => {
		if (materials) {
			changeSelectedMaterial(0);
			setIDColor(defaultIDColor);
		}
	}, [materials]);

	const loadMaterials = (viewer) => {
		if (viewer) {
			const materialsTemp = [];
			const modelMaterials = viewer.model.materials;
			if (originalMaterials.length <= 0) {
				const materials = [];
				modelMaterials.map((material, index) => {
					const colorRGB = material.pbrMetallicRoughness.baseColorFactor;
					materials.push({
						name: material.name,
						index: index,
						color: rgbToHex(colorRGB[0], colorRGB[1], colorRGB[2]),
					});
				});

				setOriginalMaterials(materials);
			} else {
				viewer.model.materials.forEach((material, index) => {
					if (!materialIsGroup(material)) {
						material.pbrMetallicRoughness.setBaseColorFactor(
							hextoRGB(originalMaterials[index].color)
						);
					}
				});
			}

			if (editedMaterials) {
				modelMaterials.map((material, index) => {
					const foundMaterial = editedMaterials
						? editedMaterials.find((mat) => mat.index == index)
						: null;

					const colorRGB = material.pbrMetallicRoughness.baseColorFactor;
					const colorHex = rgbToHex(colorRGB[0], colorRGB[1], colorRGB[2]);

					if (foundMaterial && foundMaterial.color != colorHex) {
						viewer.model.materials[
							foundMaterial.index
						].pbrMetallicRoughness.setBaseColorFactor(
							hextoRGB(foundMaterial.color)
						);
					}

					materialsTemp.push({
						name: material.name,
						index: index,
						color: foundMaterial ? foundMaterial.color : colorHex,
					});
				});
			}

			setMaterials(materialsTemp);
		}
	};

	const changeMaterialColor = (color) => {
		setSelectedMaterial({
			...selectedMaterial,
			color: color,
		});

		viewer.model.materials[
			selectedMaterial.index
		].pbrMetallicRoughness.setBaseColorFactor(hextoRGB(color));

		saveMaterialChanges();
	};

	const saveMaterialChanges = () => {
		let materialsTemp = materials;
		const materialToSave = selectedMaterial;

		materialsTemp[materialToSave.index].color = materialToSave.color;

		onMaterialChange(materialToSave);
		setMaterials(materialsTemp);
	};

	const changeSelectedMaterial = async (index) => {
		if (materials.length > 0) {
			let color = materials[index].color;
			color = Array.isArray(color)
				? rgbToHex(color[0], color[1], color[2])
				: color;

			setSelectedMaterial({
				index: parseInt(index),
				color: color,
			});
		}
	};

	const listMaterials = () => {
		const options = [];
		materials.map((material, index) => {
			options.push(
				<option key={index} value={index}>
					{material.name}
				</option>
			);
		});

		return (
			<Form.Select
				id="material-selector"
				value={selectedMaterial.index}
				onChange={(e) => {
					changeSelectedMaterial(e.target.value);
				}}
			>
				{options}
			</Form.Select>
		);
	};

	const hextoRGB = (hex) => {
		var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result
			? [
					parseInt(result[1], 16) / 255,
					parseInt(result[2], 16) / 255,
					parseInt(result[3], 16) / 255,
					1,
			  ]
			: null;
	};

	const componentToHex = (c) => {
		var hex = Math.round(c * 255).toString(16);
		return hex.length == 1 ? '0' + hex : hex;
	};

	const rgbToHex = (r, g, b) => {
		return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
	};

	const resetMaterials = () => {
		originalMaterials.forEach((material, index) => {
			if (!materialIsGroup(viewer.model.materials[index])) {
				viewer.model.materials[index].pbrMetallicRoughness.setBaseColorFactor(
					hextoRGB(material.color)
				);
			}
		});

		setMaterials(originalMaterials);
		setIDColor('#ffffff');
		onIDColorChange('#ffffff');
		onResetMaterials();
	};

	const materialIsGroup = (material) => {
		let isGroup = false;
		for (var [key, value] of material[
			Object.getOwnPropertySymbols(material)[2]
		].entries()) {
			if (key.type == 'Group') {
				isGroup = true;
				break;
			}
		}

		return isGroup;
	};

	return (
		<>
			<SimplifiedCard headerContent={t('variant_editor')}>
				{materials.length > 0 && (
					<Container>
						<Row>
							<Col>
								<Form.Label htmlFor="variant-color-selector">
									{t('variant_color')}
								</Form.Label>
								<Form.Control
									id="variant-color-selector"
									type={'color'}
									value={IDColor}
									onChange={(e) => {
										setIDColor(e.target.value);
										onIDColorChange(e.target.value);
									}}
								/>
							</Col>
						</Row>
						<hr />
						<Row>
							<Col>
								<Form.Label htmlFor="material-selector">
									{t('material')}
								</Form.Label>
								{materials.length > 0 && listMaterials()}
							</Col>
						</Row>
						<br />
						<Row>
							<Col>
								<Form.Label htmlFor="color-selector">{t('material_color')}</Form.Label>
								<Form.Control
									id="color-selector"
									type={'color'}
									onChange={(e) => changeMaterialColor(e.target.value)}
									value={selectedMaterial.color}
								/>
							</Col>
						</Row>
						<br />
						<Row>
							<Col>
								<Button onClick={() => resetMaterials()}>
									{t('common:reset')}
								</Button>
							</Col>
							<Col>
								<Button variant="danger" onClick={() => onDeleteVariant()}>
									{t('common:delete')}
								</Button>
							</Col>
						</Row>
					</Container>
				)}
			</SimplifiedCard>
		</>
	);
};

MaterialEditor.propTypes = {
	variant: PropTypes.object,
	onMaterialChange: PropTypes.func,
	onIDColorChange: PropTypes.func,
	defaultIDColor: PropTypes.string,
	onResetMaterials: PropTypes.func,
	onDeleteVariant: PropTypes.func,
};

MaterialEditor.defaultProps = {
	variant: {},
	onMaterialChange: () => {},
	onIDColorChange: () => {},
	defaultIDColor: '#fffff',
	onResetMaterials: () => {},
	onDeleteVariant: () => {},
};

export default MaterialEditor;
