import { faPaypal } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { PaymentRequests, PayPalRequests } from '../../../requests';
import { toast } from 'react-toastify';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import './style.css';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { Routes } from '../../../routes';

const PayPal = (props) => {
	const { skuAmount, plan } = props;
	const { t } = useTranslation('components');
	const dispatch = useDispatch();
	const history = useHistory();

	const [isPaypalLinked, setIsPaypalLinked] = useState(false);

	useEffect(() => {
		dispatch(
			PayPalRequests.checkAccountStatus((response) => {
				if (response.status === 200) {
					setIsPaypalLinked(true);
				} else {
					setIsPaypalLinked(false);
				}
			})
		);
	}, []);

	const getPlanID = () => {
		switch (plan) {
			case 'monthly':
				return process.env.REACT_APP_PAYPAL_MONTHLY_SUB;
			case 'annual':
				return process.env.REACT_APP_PAYPAL_YEARLY_SUB;
			default:
				break;
		}
	};

	return (
		<div>
			<section className="d-flex align-items-center mt-4 align-btn">
				{isPaypalLinked ? (
					<div>
						<PayPalScriptProvider
							options={{
								'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID,
								vault: true,
							}}
						>
							<PayPalButtons
								style={{
									shape: 'rect',
									color: 'gold',
									layout: 'vertical',
									label: 'subscribe',
								}}
								createSubscription={(data, actions) => {
									return actions.subscription.create({
										plan_id: getPlanID(),
										quantity: skuAmount,
									});
								}}
								onApprove={(data, actions) => {
									dispatch(
										PaymentRequests.createPayment(
											data.subscriptionID,
											(res) => {
												if (res.status === 200) {
													history.push(Routes.Payment.path);
												} else {
													toast.error(t(`error:${res.error}`));
												}
											}
										)
									);
								}}
								onError={() => {
									toast.error(t('error:error_with_paypal_payment'));
								}}
							/>
						</PayPalScriptProvider>
					</div>
				) : (
					<div style={{ display: 'block', textAlign: 'center' }}>
						<h4>{t('common:paypal')}</h4>
						<p>{t('payment_services.login_to_paypal_for_payment')}</p>
						<Button
							className="paypal-btn"
							onClick={() => {
								window.location.href = `${process.env.REACT_APP_PAYPAL_URL}/connect?flowEntry=static&client_id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&scope=profile email https://uri.paypal.com/services/paypalattributes&redirect_uri=${process.env.REACT_APP_DASHBOARD_URL}payment/paypal/link`;
							}}
						>
							<FontAwesomeIcon icon={faPaypal} className="paypal-icon" />
							{t('payment_services.login_with_paypal')}
						</Button>
					</div>
				)}
			</section>
		</div>
	);
};

PayPal.propTypes = {
	skuAmount: PropTypes.number.isRequired,
	plan: PropTypes.string.isRequired,
};

export default PayPal;
