import axios from 'axios';
import { RequestCallback } from 'typings/custom';

export const getUserReport = (callback: RequestCallback) => async () => {
	await axios
		.get('/admin/report/user')
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};

export const getPaymentReport = (callback: RequestCallback) => async () => {
	await axios
		.get('/admin/report/payment')
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};

export const getEntelReport = (query: string, callback: RequestCallback) => async () => {
	await axios
		.get('/admin/report/entel?' + query)
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};

export const getProductReport = (query: String, callback: RequestCallback) => async () => {
	await axios
		.get('/admin/report/product?' + query)
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};