import axios from 'axios';
import { RequestCallback } from 'typings/custom';

export const uploadModel =
	(model_id: ID, file: any, platform: string, callback: RequestCallback) => async () => {
		var data = new FormData();
		data.append('file', file);

		await axios
			.post(`/model/${model_id}/${platform}`, data, {
				headers: {
					'content-type': 'multipart/form-data',
				},
			})
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const deleteModel =
	(model_id: ID, platform: string, callback: RequestCallback) => async () => {
		await axios
			.delete(`/model/${model_id}/${platform}`)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const createModel = (variant_id: ID, callback: RequestCallback) => async () => {
	await axios
		.post('/model/', { variant_id: variant_id })
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};

export const addPoster = (model_id: ID, file: any, callback: RequestCallback) => async () => {
	var data = new FormData();
	data.append('file', file);

	await axios
		.post(`/model/${model_id}/poster`, data, {
			headers: {
				'content-type': 'multipart/form-data',
			},
		})
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};

export const removePoster = (model_id: ID, callback: RequestCallback) => async () => {
	await axios
		.delete(`/model/${model_id}/poster`)
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};
