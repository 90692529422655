import React from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = (props) => {
	const { t } = useTranslation();

	return (
		<Card border="light" className="shadow-sm">
			<Card.Body>
				<h5>{t('notice_of_privacy')}</h5>
				<br></br>
				<div className="d-block" style={{ textAlign: 'justify' }}>
					<p>
						Gracias por elegir ser parte de nuestra comunidad en AppAR SPA.
						("Compañía", "nosotros", "nos", "nuestro"). Estamos comprometidos a
						proteger su información personal y su derecho a la privacidad. Si
						tiene alguna pregunta o inquietud sobre este aviso de privacidad o
						nuestras prácticas con respecto a su información personal,
						comuníquese con nosotros a contacto@appar.io.
					</p>
					<p>
						Cuando visita nuestro sitio web https://www.appar.io (el "Sitio
						web") y, de manera más general, utiliza cualquiera de nuestros
						servicios (los "Servicios", que incluyen el Sitio web), agradecemos
						que nos confíe Tu información personal. Nos tomamos muy en serio su
						privacidad. En este aviso de privacidad, buscamos explicarte de la
						manera más clara posible qué información recopilamos, cómo la usamos
						y qué derechos tienes en relación con ella. Esperamos que se tome un
						tiempo para leerlo detenidamente, ya que es importante. Si hay algún
						término en este aviso de privacidad con el que no está de acuerdo,
						interrumpa el uso de nuestros Servicios de inmediato.
					</p>
					<p>
						Este aviso de privacidad se aplica a toda la información recopilada
						a través de nuestros Servicios (que, como se describe anteriormente,
						incluye nuestro sitio web), así como cualquier servicio, ventas,
						marketing o eventos relacionados. El Sitio está destinado a usuarios
						que tengan al menos 18 años. Las personas menores de 18 años no
						pueden usar o registrarse en el Sitio.
					</p>
					<p>
						Lea atentamente este aviso de privacidad, ya que le ayudará a
						comprender qué hacemos con la información que recopilamos.{' '}
					</p>
					<h5 className="mb-4">1. ¿QUÉ INFORMACIÓN RECOPILAMOS?</h5>
					<p>Información personal que nos divulga.</p>
					<p>
						En resumen: recopilamos la información personal que nos proporciona.
						Recopilamos información personal que nos proporciona voluntariamente
						cuando se registra en el sitio web, expresa su interés en obtener
						información sobre nosotros o nuestros productos y servicios, cuando
						participa en actividades en el sitio web o cuando se comunica con
						nosotros.
					</p>
					<p>
						La información personal que recopilamos depende del contexto de sus
						interacciones con nosotros y el sitio web, las elecciones que haga y
						los productos y funciones que utilice. La información personal que
						recopilamos puede incluir lo siguiente:{' '}
					</p>
					<p>
						Datos de pago. Podemos recopilar los datos necesarios para procesar
						su pago si realiza compras, como el número de su instrumento de pago
						(como un número de tarjeta de crédito) y el código de seguridad
						asociado con su instrumento de pago. Todos los datos de pago son
						almacenados por{' '}
						<a href="https://www.flow.cl/index.php" target="_blank">
							FLOW
						</a>
						. Puede encontrar los enlaces de su aviso de privacidad aquí:{' '}
						<a href="https://www.flow.cl/privacidad.php" target="_blank">
							https://www.flow.cl/privacidad.php
						</a>
						.
					</p>
					<p>
						Toda la información personal que nos proporcione debe ser verdadera,
						completa y precisa, y debe notificarnos cualquier cambio en dicha
						información personal.{' '}
					</p>
					<p>Información recopilada automáticamente </p>
					<p>
						En resumen: Cierta información, como su dirección de Protocolo de
						Internet (IP) y / o las características del navegador y del
						dispositivo, se recopila automáticamente cuando visita nuestro sitio
						web.{' '}
					</p>
					<p>
						Recopilamos automáticamente cierta información cuando visita, usa o
						navega por el sitio web. Esta información no revela su identidad
						específica (como su nombre o información de contacto), pero puede
						incluir información de uso y dispositivo, como su dirección IP,
						navegador y características del dispositivo, sistema operativo,
						preferencias de idioma, URL de referencia, nombre del dispositivo,
						país, ubicación., información sobre cómo y cuándo utiliza nuestro
						sitio web y otra información técnica. Esta información es
						principalmente necesaria para mantener la seguridad y el
						funcionamiento de nuestro sitio web, y para nuestros fines de
						análisis e informes internos.{' '}
					</p>
					<p>
						Como muchas empresas, también recopilamos información a través de
						cookies y tecnologías similares.
					</p>

					<h5 className="mb-4">
						2. ¿SE COMPARTIRÁ SU INFORMACIÓN CON ALGUIEN?
					</h5>
					<p>
						En resumen: solo compartimos información con su consentimiento, para
						cumplir con las leyes, para brindarle servicios, para proteger sus
						derechos o para cumplir con obligaciones comerciales. Podemos
						procesar o compartir sus datos que tenemos en base a la siguiente
						base legal:
					</p>
					<p>
						Consentimiento: podemos procesar sus datos si nos ha dado su
						consentimiento específico para usar su información personal para un
						propósito específico.
					</p>
					<p>
						Intereses legítimos: podemos procesar sus datos cuando sea
						razonablemente necesario para lograr nuestros intereses comerciales
						legítimos.{' '}
					</p>
					<p>
						Ejecución de un contrato: cuando hayamos celebrado un contrato con
						usted, podemos procesar su información personal para cumplir con los
						términos de nuestro contrato.{' '}
					</p>
					<p>
						Obligaciones legales: Podemos divulgar su información cuando estemos
						legalmente obligados a hacerlo para cumplir con la ley aplicable,
						las solicitudes gubernamentales, un procedimiento judicial, una
						orden judicial o un proceso legal, como en respuesta a una orden
						judicial o una citación ( incluso en respuesta a las autoridades
						públicas para cumplir con los requisitos de seguridad nacional o de
						aplicación de la ley).{' '}
					</p>
					<p>
						Intereses vitales: podemos divulgar su información cuando creamos
						que es necesario para investigar, prevenir o tomar medidas con
						respecto a posibles violaciones de nuestras políticas, sospecha de
						fraude, situaciones que impliquen amenazas potenciales a la
						seguridad de cualquier persona y actividades ilegales, o como
						evidencia en litigio en el que estamos involucrados.{' '}
					</p>
					<p>
						Más específicamente, es posible que necesitemos procesar sus datos o
						compartir su información personal en las siguientes situaciones:{' '}
					</p>
					<p>
						Transferencias comerciales. Podemos compartir o transferir su
						información en relación con, o durante las negociaciones de,
						cualquier fusión, venta de activos de la compañía, financiamiento o
						adquisición de todo o una parte de nuestro negocio a otra compañía.{' '}
					</p>

					<h5 className="mb-4">
						3. ¿UTILIZAMOS COOKIES Y OTRAS TECNOLOGÍAS DE SEGUIMIENTO?
					</h5>
					<p>
						En resumen: podemos utilizar cookies y otras tecnologías de
						seguimiento para recopilar y almacenar su información. Podemos
						utilizar cookies y tecnologías de seguimiento similares (como
						balizas web y píxeles) para acceder o almacenar información. La
						información específica sobre cómo usamos dichas tecnologías y cómo
						puede rechazar ciertas cookies se establece en nuestro Aviso de
						cookies.{' '}
					</p>

					<h5 className="mb-4">
						4. ¿CUÁNTO TIEMPO CONSERVAMOS SU INFORMACIÓN?
					</h5>
					<p>
						En resumen: Conservamos su información durante el tiempo que sea
						necesario para cumplir con los propósitos descritos en este aviso de
						privacidad, a menos que la ley exija lo contrario.{' '}
					</p>
					<p>
						Solo conservaremos su información personal durante el tiempo que sea
						necesario para los fines establecidos en este aviso de privacidad, a
						menos que la ley exija o permita un período de retención más
						prolongado (como impuestos, contabilidad u otros requisitos
						legales). Ningún propósito de este aviso requerirá que conservemos
						su información personal por más de ______
					</p>
					<p>
						Cuando no tengamos una necesidad comercial legítima en curso para
						procesar su información personal, eliminaremos o anonimizaremos
						dicha información o, si esto no es posible (por ejemplo, porque su
						información personal se ha almacenado en archivos de respaldo), lo
						haremos de manera segura almacenar su información personal y
						aislarla de cualquier procesamiento posterior hasta que sea posible
						la eliminación.{' '}
					</p>

					<h5 className="mb-4">5. ¿CÓMO MANTENEMOS SEGURA SU INFORMACIÓN?</h5>
					<p>
						En resumen: nuestro objetivo es proteger su información personal a
						través de un sistema de medidas de seguridad organizativas y
						técnicas.{' '}
					</p>
					<p>
						Hemos implementado medidas de seguridad técnicas y organizativas
						apropiadas diseñadas para proteger la seguridad de cualquier
						información personal que procesamos. Sin embargo, a pesar de
						nuestras salvaguardas y esfuerzos para proteger su información, no
						se puede garantizar que ninguna transmisión electrónica a través de
						Internet o tecnología de almacenamiento de información sea 100%
						segura, por lo que no podemos prometer ni garantizar que los piratas
						informáticos, los ciberdelincuentes u otros terceros no autorizados
						no serán capaz de vencer nuestra seguridad y recopilar, acceder,
						robar o modificar su información de manera inapropiada. Aunque
						haremos todo lo posible para proteger su información personal, la
						transmisión de información personal hacia y desde nuestro sitio web
						es bajo su propio riesgo. Solo debe acceder al sitio web dentro de
						un entorno seguro.{' '}
					</p>

					<h5 className="mb-4">6. ¿RECOPILAMOS INFORMACIÓN DE MENORES?</h5>
					<p>
						En resumen: no recopilamos ni comercializamos a sabiendas datos de
						niños menores de 18 años.{' '}
					</p>
					<p>
						No solicitamos a sabiendas datos ni comercializamos a niños menores
						de 18 años. Al utilizar el sitio web, usted declara que tiene al
						menos 18 años o que es el padre o tutor de dicho menor y da su
						consentimiento para que dicho menor dependiente utilice el sitio
						web. Si nos enteramos de que se ha recopilado información personal
						de usuarios menores de 18 años, desactivaremos la cuenta y tomaremos
						medidas razonables para eliminar de inmediato dichos datos de
						nuestros registros. Si tiene conocimiento de algún dato que hayamos
						recopilado de niños menores de 18 años, comuníquese con nosotros a
						contacto@appar.io.{' '}
					</p>

					<h5 className="mb-4">7. ¿CUÁLES SON SUS DERECHOS DE PRIVACIDAD?</h5>
					<p>
						En resumen: en algunas regiones, como el Espacio Económico Europeo
						(EEE) y el Reino Unido (Reino Unido), tiene derechos que le permiten
						un mayor acceso y control sobre su información personal. Puede
						revisar, cambiar o cancelar su cuenta en cualquier momento. En
						algunas regiones (como el EEE y el Reino Unido), tiene ciertos
						derechos según las leyes de protección de datos aplicables. Estos
						pueden incluir el derecho (i) a solicitar acceso y obtener una copia
						de su información personal, (ii) a solicitar rectificación o
						borrado; (iii) para restringir el procesamiento de su información
						personal; y (iv) en su caso, a la portabilidad de datos. En
						determinadas circunstancias, también puede tener derecho a oponerse
						al procesamiento de su información personal. Para realizar dicha
						solicitud, utilice los datos de contacto que se proporcionan a
						continuación. Consideraremos y actuaremos ante cualquier solicitud
						de acuerdo con las leyes de protección de datos aplicables.
					</p>

					<p>
						Si confiamos en su consentimiento para procesar su información
						personal, tiene derecho a retirar su consentimiento en cualquier
						momento. Sin embargo, tenga en cuenta que esto no afectará la
						legalidad del procesamiento antes de su retiro, ni afectará el
						procesamiento de su información personal realizado en base a motivos
						legales de procesamiento distintos al consentimiento.
					</p>
					<p>
						Si reside en el EEE o el Reino Unido y cree que estamos procesando
						ilegalmente su información personal, también tiene derecho a
						presentar una queja ante la autoridad supervisora de protección de
						datos local. Puede encontrar sus datos de contacto aquí:
						http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.{' '}
					</p>
					<p>
						Si reside en Suiza, los datos de contacto de las autoridades de
						protección de datos están disponibles aquí:
						https://www.edoeb.admin.ch/edoeb/en/home.html
					</p>
					<p>
						Si tiene preguntas o comentarios sobre sus derechos de privacidad,
						puede enviarnos un correo electrónico a contacto@appar.io.{' '}
					</p>

					<p>Información de la cuenta </p>
					<p>
						Si en algún momento desea revisar o cambiar la información de su
						cuenta o cancelar su cuenta, puede:{' '}
					</p>
					<p>Contáctenos usando la información de contacto proporcionada.</p>
					<p>
						Si solicita cancelar su cuenta, desactivaremos o eliminaremos su
						cuenta e información de nuestras bases de datos activas. Sin
						embargo, podemos retener cierta información en nuestros archivos
						para prevenir fraudes, solucionar problemas, ayudar con cualquier
						investigación, hacer cumplir nuestros Términos de uso y / o cumplir
						con los requisitos legales aplicables.
					</p>
					<p>
						Optar por no recibir marketing por correo electrónico: puede darse
						de baja de nuestra lista de correo electrónico de marketing en
						cualquier momento haciendo clic en el enlace para darse de baja en
						los correos electrónicos que le enviamos o comunicándose con
						nosotros utilizando los detalles que se proporcionan a continuación.
						Luego será eliminado de la lista de correo electrónico de marketing;
						sin embargo, aún podemos comunicarnos con usted, por ejemplo, para
						enviarle correos electrónicos relacionados con el servicio que son
						necesarios para la administración y el uso de su cuenta, para
						responder a solicitudes de servicio o para otros fines no
						comerciales. Para optar por no participar, puede:{' '}
					</p>
					<p>
						Acceda a la configuración de su cuenta y actualice las preferencias.
					</p>

					<h5 className="mb-4">
						8. CONTROLES PARA LAS CARACTERÍSTICAS DE NO SEGUIR
					</h5>
					<p>
						La mayoría de los navegadores web y algunos sistemas operativos y
						aplicaciones móviles incluyen una función o configuración No
						rastrear ("DNT") que puede activar para indicar su preferencia de
						privacidad para que no se controlen y recopilen datos sobre sus
						actividades de navegación en línea. En esta etapa, no se ha
						finalizado ningún estándar tecnológico uniforme para reconocer e
						implementar señales DNT. Como tal, actualmente no respondemos a las
						señales del navegador DNT ni a ningún otro mecanismo que comunique
						automáticamente su elección de no ser rastreado en línea. Si se
						adopta un estándar para el seguimiento en línea que debemos seguir
						en el futuro, le informaremos sobre esa práctica en una versión
						revisada de este aviso de privacidad.{' '}
					</p>

					<h5 className="mb-4">
						9. ¿TIENEN LOS RESIDENTES DE CALIFORNIA DERECHOS DE PRIVACIDAD
						ESPECÍFICOS?
					</h5>
					<p>
						En resumen: Sí, si es residente de California, se le otorgan
						derechos específicos con respecto al acceso a su información
						personal.{' '}
					</p>
					<p>
						La sección 1798.83 del Código Civil de California, también conocida
						como la ley "Shine The Light", permite a nuestros usuarios que son
						residentes de California solicitar y obtener de nosotros, una vez al
						año y de forma gratuita, información sobre categorías de información
						personal (si corresponde) que nosotros divulgada a terceros con
						fines de marketing directo y los nombres y direcciones de todos los
						terceros con los que compartimos información personal en el año
						calendario inmediatamente anterior. Si usted es un residente de
						California y desea realizar una solicitud de este tipo, envíenos su
						solicitud por escrito utilizando la información de contacto que se
						proporciona a continuación.{' '}
					</p>
					<p>
						Si es menor de 18 años, reside en California y tiene una cuenta
						registrada en el sitio web, tiene derecho a solicitar la eliminación
						de los datos no deseados que publique públicamente en el sitio web.
						Para solicitar la eliminación de dichos datos, comuníquese con
						nosotros utilizando la información de contacto que se proporciona a
						continuación e incluya la dirección de correo electrónico asociada
						con su cuenta y una declaración de que reside en California. Nos
						aseguraremos de que los datos no se muestren públicamente en el
						sitio web, pero tenga en cuenta que es posible que los datos no se
						eliminen completa o completamente de todos nuestros sistemas (por
						ejemplo, copias de seguridad, etc.).{' '}
					</p>

					<h5 className="mb-4">10. ¿HACEMOS ACTUALIZACIONES A ESTE AVISO?</h5>
					<p>
						En resumen: Sí, actualizaremos este aviso según sea necesario para
						cumplir con las leyes pertinentes.{' '}
					</p>
					<p>
						Es posible que actualicemos este aviso de privacidad de vez en
						cuando. La versión actualizada se indicará con una fecha "Revisada"
						actualizada y la versión actualizada entrará en vigencia tan pronto
						como esté disponible. Si realizamos cambios sustanciales a este
						aviso de privacidad, podemos notificarle publicando un aviso de
						manera prominente de dichos cambios o enviándole directamente una
						notificación. Le recomendamos que revise este aviso de privacidad
						con frecuencia para estar informado de cómo protegemos su
						información.{' '}
					</p>

					<h5 className="mb-4">
						11. ¿CÓMO PUEDE CONTACTARNOS SOBRE ESTE AVISO?
					</h5>
					<p>
						Si tiene preguntas o comentarios sobre este aviso, puede enviarnos
						un correo electrónico a contacto@appar.io o por correo postal a:{' '}
					</p>
					<p> - AppAR SPA</p>
					<p> - Avda. La Dehesa 181 Of. 809</p>
					<p> - Lo Barnechea, Santiago de Chile</p>

					<h5 className="mb-4">
						12. ¿CÓMO PUEDE REVISAR, ACTUALIZAR O ELIMINAR LOS DATOS QUE
						RECOPILAMOS DE USTED?
					</h5>
					<p>
						De acuerdo con las leyes aplicables de su país, es posible que tenga
						derecho a solicitar acceso a la información personal que recopilamos
						de usted, cambiar esa información o eliminarla en algunas
						circunstancias. Para solicitar revisar, actualizar o eliminar su
						información personal, envíe un formulario de solicitud{' '}
					</p>
				</div>
			</Card.Body>
		</Card>
	);
};

export default PrivacyPolicy;
