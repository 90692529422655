import axios from 'axios';
import { RequestCallback, User } from 'typings/custom';

export const userData = (callback: RequestCallback) => async () => {
	await axios
		.get('/user/')
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};

export const signup = (newUser: User, callback: RequestCallback) => async () => {
	await axios
		.post('/user/', newUser)
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};

export const update = (user: User, callback: RequestCallback) => async () => {
	await axios
		.put(`/user/${user.user_id}`, user)
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};

export const verify = (token: string, callback: RequestCallback) => async () => {
	await axios
		.get(`/user/verify/${token}`)
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};

export const recoverPassword = (email: string, callback: RequestCallback) => async () => {
	await axios
		.post('/auth/forgot', { email: email })
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};

export const resetPassword =
	(token: string, password: string, callback: RequestCallback) => async () => {
		await axios
			.post('/auth/reset', { password: password, token: token })
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const getUsers = (callback: RequestCallback) => async () => {
	await axios
		.get('/user/users')
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};
