import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'react-bootstrap';

const GridPagination = (props) => {
	const { currentPage, lastPage, onPageChange } = props;

	const generatePageIndexes = () => {
		let elements = [];

		for (let i = 1; i <= lastPage; i++) {
			elements.push(
				<Pagination.Item
					key={i}
					active={i === currentPage}
					onClick={() => onPageChange(i)}
				>
					{i}
				</Pagination.Item>
			);
		}

		return elements;
	};

	return (
		<div>
			<Pagination>
				<Pagination.First
					onClick={() => onPageChange(1)}
					disabled={currentPage === 1}
				/>
				<Pagination.Prev
					onClick={() => onPageChange(currentPage - 1)}
					disabled={currentPage === 1}
				/>

				{generatePageIndexes()}

				<Pagination.Next
					onClick={() => onPageChange(currentPage + 1)}
					disabled={currentPage == lastPage}
				/>
				<Pagination.Last
					onClick={() => onPageChange(lastPage)}
					disabled={currentPage == lastPage}
				/>
			</Pagination>
		</div>
	);
};

GridPagination.propTypes = {
	currentPage: PropTypes.number.isRequired,
	lastPage: PropTypes.number.isRequired,
	onPageChange: PropTypes.func,
};

GridPagination.defaultProps = {};

export default GridPagination;
