import React, { ReactNode, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import SimplifiedModal from 'components/SimplifiedModal';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface Props {
	steps: Array<Step>,
	start: boolean,
	isFirstTime: boolean,
	isBtnVisible: boolean
}

const JoyrideOnboard = (props: Props) => {
	const { isFirstTime, isBtnVisible, steps } = props;
	const { t } = useTranslation(['components', 'common']);
	const [run, setRun] = useState(false);
	const [modal, setModal] = useState(false);

	const locales = {
		back: t('common:back'),
		close: t('common:close'),
		last: t('common:finalize'),
		next: t('common:next'),
		open: t('common:open'),
		skip: t('common:skip'),
	};

	useEffect(() => {
		if (localStorage.getItem('tutorialDone') !== "1") {
			setModal(isFirstTime);
		}
	}, []);

	const handleClickStart = (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault();
		setRun(true);
		setModal(false);
	};

	const handleJoyrideCallback = (data: CallBackProps) => {
		const { status } = data;
		if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
			setRun(false);
			localStorage.setItem('tutorialDone', "1");
		}
	};

	return (
		<>
			{isBtnVisible && (
				<Button
					onClick={(e) => handleClickStart(e)}
					className="copilot-btn"
					variant="tertiary"
				>
					<FontAwesomeIcon icon={faQuestion as IconProp} className="mb-2" />
				</Button>
			)}
			<Joyride
				callback={handleJoyrideCallback}
				continuous
				hideBackButton
				hideCloseButton
				run={run}
				scrollToFirstStep
				showProgress
				showSkipButton
				steps={steps}
				locale={locales}
				styles={{
					options: {
						zIndex: 10000,
						primaryColor: '#FFCE1F',
					},
					buttonNext: {
						backgroundColor: '#009FE3',
					},
					beacon: {
						border: '#FFCE1F',
					},
				}}
			/>
			<SimplifiedModal
				open={modal}
				onHide={() => {
					setModal(false);
				}}
				headerContent={<div>{t('common:welcome') as ReactNode}</div>}
				footerContent={
					<>
						<Button onClick={() => setModal(false)}>
							{t('common:cancel') as ReactNode}
						</Button>
						<Button
							variant="secondary"
							form="editQr"
							type="submit"
							onClick={(e) => handleClickStart(e)}
						>
							{t('components:joyride.start_tutorial') as ReactNode}
						</Button>
					</>
				}
			>
				<h6>{t('components:joyride.welcomeDescription') as ReactNode}</h6>
			</SimplifiedModal>
		</>
	);
};

JoyrideOnboard.propTypes = {
	steps: PropTypes.arrayOf(PropTypes.object),
	start: PropTypes.bool,
	isFirstTime: PropTypes.bool,
	isBtnVisible: PropTypes.bool,
};

JoyrideOnboard.defaultProps = {
	steps: [
		{
			content: 'Comienza acá',
			target: '.body',
		},
	],
	open: false,
	isFirstTime: false,
	isBtnVisible: true,
};

export default JoyrideOnboard;


