import axios from 'axios';
import {
	Credentials,
	ProviderProfile,
	RequestCallback
} from 'typings/custom';

export const saveToken = (token: string) => {
	localStorage.setItem('token', token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export const tokenSignin = (callback: RequestCallback) => async () => {
	await axios
		.get('/auth/')
		.then((response) => {
			saveToken(response.data);
			callback(response); //tecnicamente no necesario
		})
		.catch((error) => {
			callback(error.response);
		});
};

export const signin =
	(credentials: Credentials, callback: RequestCallback) => async () => {
		await axios
			.post('/auth/', credentials)
			.then((response) => {
				saveToken(response.data);
				callback(response); //tecnicamente no necesario
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const signOut = (callback: any) => async () => {
	localStorage.removeItem('token');
	axios.defaults.headers.common['Authorization'] = null;
	callback({ status: 200 });
};

export const checkSession = (callback: (isValid: boolean) => void) => async () => {
	if (!localStorage.getItem('token')) {
		callback(false);
	} else {
		await axios
			.get('/auth/')
			.then((response) => {
				callback(true);
			})
			.catch((error) => {
				callback(false);
			});
	}
};

export const apparSocialLogin = (token: string, callback: any) => async () => {
	await axios.put('/auth/social', {token: token})
	.then((response) => {
		callback(response);
	})
	.catch((error) => {
		callback(error.response);
	});
}

export const googleSignIn =
	(providerProfile: ProviderProfile, callback: RequestCallback) => async () => {
		await axios
			.post('/auth/social', providerProfile)
			.then((response) => {
				saveToken(response.data);
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};
