export const Routes = {
	// Admin pages
	AdminApiAnalytics: { path: '/admin/apianalytics' },
	AdminAnalytics: { path: '/admin/analytics' },
	AdminIntegrity: { path: '/admin/integrity' },
	AdminPayments: { path: '/admin/payments' },
	AdminProducts: { path: '/admin/products' },
	AdminProviders: { path: '/admin/providers' },
	AdminStore: { path: '/admin/store' },
	AdminTeamWear: { path: '/admin/teamwear'},
	AdminTopProducts: { path: '/admin/top' },
	AdminUser: { path: '/admin/user' },
	GlobalMessage: { path: '/admin/globalmessage' },
	Reports: { path: '/admin/report' },

	// Pages
	Analytics: { path: '/analytics' },
	BuyPlan: { path: '/buy' },
	Category: { path: '/category' },
	Checkout: { path: '/checkout' },
	Collection: { path: '/collection' },
	CreateCollection: { path: '/collection/new' },
	CreateProduct: { path: '/product/new' },
	EditProfile: { path: '/profile' },
	ForgotPassword: { path: '/forgot' },
	FrequentQuestions: { path: '/faq' },
	Link: { path: '/link' },
	Overview: { path: '/overview' },
	Payment: { path: '/payment' },
	Pricing: { path: '/pricing' },
	PricingPreview: { path: '/pricing/preview' },
	Products: { path: '/product' },
	ResetPassword: { path: '/reset' },
	Signin: { path: '/signin' },
	Signup: { path: '/signup' },
	Store: { path: '/store' },
	StoreLink: { path: '/store/link/:provider' },
	Support: { path: '/support' },
	ThreeDEditor: {path: '/3deditor'},
	ThreeDViewer: { path: '/3dviewer' },
	Tutorial: { path: '/tutorial' },
	User: { path: '/user' },
	Verify: { path: '/verify' },
	SocialLogin: { path: '/auth' },

	// Public pages
	FAQ: { path: '/public/faq' },
	PrivacyPolicy: { path: '/public/privacy' },
	PublicSupport: { path: '/public/support' },
	Terms: { path: '/public/terms' },

	//integrations
	Shopify: { path: '/integration/shopify' },
	TiendaNube: { path: '/integration/tiendanube' },
	Multivende: { path: '/integration/multivende' },
	Vtex: { path: '/integration/vtex' },

	//payments
	PaypalLink: { path: '/payment/paypal/link' },

	// docs

	// components
	Forms: { path: '/components/forms' },
	Tables: { path: '/components/tables' },
	WidgetsComponent: { path: '/components/widgets' },
};
