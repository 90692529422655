import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChartUtil, DateUtils } from '../../utils';
import { GraphWidget } from '../Widgets';
import PropTypes from 'prop-types';
import { useState as hookState } from '@hookstate/core';
import { chartStore } from '../../store';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import * as locales from 'react-date-range/dist/locale';
import { Button, Col, OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Loading from '../Loading';

const SimplifiedStatChart = (props) => {
	const { t } = useTranslation(['common', 'date', 'components']);
	const { dataY1, dataY2, dataY1Label, dataY2Label, onDateChange, externalLoading } = props;

	const [dates, setDates] = useState([
		{
			startDate: moment().startOf('month').toDate(),
			endDate: moment().subtract(1, 'days').format("YYYY-MM-DD"),
			key: 'selection',
		},
	]);

	const [graphData, setGraphData] = useState({
		labels: DateUtils.getDaysBetweenDates(dates[0].startDate, dates[0].endDate),
		series: [
			[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
		],
		totals: { visits: 0, augmentations: 0 },
		raw: { visits: [], augmentations: [] },
	});
	const [locale, setLocale] = useState('es');

	const [loading, setLoading] = useState(false)

	const globalChartData = hookState(chartStore);
	const today = moment().toDate();

	useEffect(() => {
		if (dataY1 && dataY2) {
			setGraphData({
				labels: DateUtils.getDaysBetweenDates(
					dates[0].startDate,
					dates[0].endDate
				),
				series: setStats(),
				totals: { visits: 0, augmentations: 0 },
				raw: { visits: [], augmentations: [] },
			});
		}
	}, [dataY1, dataY2]);

	useEffect(() => {
		if (dataY1 && dataY2) {
			setGraphData({
				labels: DateUtils.getDaysBetweenDates(
					dates[0].startDate,
					dates[0].endDate
				),
				series: setStats(),
				totals: { visits: 0, augmentations: 0 },
				raw: { visits: [], augmentations: [] },
			});
		}
	}, [dates]);

	const setStats = () => {
		globalChartData.isLoaded.set(false);
		setLoading(true)
		const days = DateUtils.getDaysBetweenDates(
			dates[0].startDate,
			dates[0].endDate
		).length;

		let augmentations = new Array(days).fill(0);
		let views = new Array(days).fill(0);

		dataY1.forEach((date) => {
			const index = parseInt(
				moment(dates[0].startDate).diff(moment(date.year + "-" + date.month + "-" + date.day), 'day') * -1
			);

			if (index >= 0) {
				views[index] = date.count;
			}
		});

		dataY2.forEach((date) => {
			const index = parseInt(
				moment(dates[0].startDate).diff(moment(date.year + "-" + date.month + "-" + date.day), 'day') * -1
			);

			if (index >= 0) {
				augmentations[index] = date.count;
			}
		});

		globalChartData.isLoaded.set(true);
		setLoading(false)
		return [views, augmentations];
	};

	const popover = (
		<Popover id="popover-basic" style={{ maxWidth: 'none' }}>
			<Popover.Body>
				<DateRange
					locale={locales[locale]}
					editableDateInputs={true}
					onChange={(item) => {
						setLoading(true);
						setDates([item.selection]);
						if(onDateChange){
							onDateChange(item);
						}
					}}
					moveRangeOnFirstSelection={false}
					ranges={dates}
					maxDate={today}
					showMonthAndYearPickers={true}
				/>
			</Popover.Body>
		</Popover>
	);

	return (
		<>
			<Col className="pt-2 px-5">
				<div className="title-graph-Widget mt-2">
					<h4>{t('components:charts.visit_augmentation_chart')}</h4>
				</div>
				<div className=" d-block div-date-range ">
					<OverlayTrigger
						rootClose
						trigger="click"
						placement="bottom"
						overlay={popover}
						className="bg-light d-block"
					>
						<Button className="btn-date ">
							<FontAwesomeIcon
								icon={faCalendarDays}
								style={{ fontSize: 20, color: '#5C499F', marginRight: '8px' }}
							/>
							{t('common:select_date')}
						</Button>
					</OverlayTrigger>
				</div>
			</Col>
			{(externalLoading != null ? externalLoading : loading) || loading ?
				<Loading />
				:
				<GraphWidget
					titles={[dataY1Label, dataY2Label]}
					values={[graphData.totals.visits, graphData.totals.augmentations]}
					chartData={graphData}
					labels={graphData.labels}
					dataY1={graphData.series[0]}
					dataY2={graphData.series[1]}
				/>
			}
		</>
	);
};

SimplifiedStatChart.propTypes = {
	dataY1: PropTypes.array.isRequired,
	dataY2: PropTypes.array,
	dataY1Label: PropTypes.string.isRequired,
	dataY2Label: PropTypes.string,
};

SimplifiedStatChart.defaultProps = {
	dataY1Label: 'dataY1',
	dataY2Label: 'dataY2',
};

export default SimplifiedStatChart;
