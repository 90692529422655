import React from 'react';
import PropTypes from 'prop-types';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';

const CodeBlock = (props) => {
	const { codeString, language } = props;

	return (
		<div>
			<button class="btn-code">Copiar</button>
			<SyntaxHighlighter language={language} style={dracula} showLineNumbers>
				{codeString}
			</SyntaxHighlighter>
		</div>
	);
};

CodeBlock.propTypes = {
	codeString: PropTypes.string.isRequired,
	language: PropTypes.string.isRequired,
};

CodeBlock.defaultProps = {
	language: 'javascript',
};

export default CodeBlock;
