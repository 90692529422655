import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const ModelViewerSlider = (props) => {
	const { models, onSelect } = props;

	useEffect(() => {}, [models]);

	const generateSlides = () => {
		let slides = [];

		models.map((model, index) => {
			slides.push(
				<model-viewer
					onClick={() => {
						onSelect({ index: index, model: model });
					}}
					src="https://model3d.shopifycdn.com/models/o/74b8183737e46415/eletta_kitchen2.glb"
					id="slide"
				></model-viewer>
			);
		});

		return slides;
	};

	return (
		<div id="slider">
			<div id="slides">
				{generateSlides()}
				<button id="slide">Nueva variante</button>
			</div>
		</div>
	);
};

ModelViewerSlider.propTypes = {
	models: PropTypes.arrayOf(PropTypes.object).isRequired,
	onSelect: PropTypes.func,
};

ModelViewerSlider.defaultProps = {};

export default ModelViewerSlider;
