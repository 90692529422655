import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { SUPPORTED_LANGUAGES } from './constants';

const languageDetector = new LanguageDetector();
languageDetector.addDetector({
	name: 'languageOnly',
	lookup(options) {
		const lang = navigator.language.replace('-', '_').split('_')[0];

		return SUPPORTED_LANGUAGES.find((lng) => lng === lang) || 'es';
	},
});
languageDetector.type = 'languageDetector';

i18n
	.use(languageDetector)
	.use(HttpApi)
	.use(initReactI18next)
	.init({
		supportedLngs: SUPPORTED_LANGUAGES,
		fallbackLng: 'es',
		debug: false,
		defaultNS: 'common',
		detection: {
			order: ['cookie', 'languageOnly'],
			caches: ['cookie'],
		},
		interpolation: {
			escapeValue: false,
		},
		react: {
			useSuspense: true,
		},
		backend: {
			loadPath: '/locales/{{lng}}/{{ns}}.json',
			allowMultiLoading: true,
		},
	});

export default i18n;
