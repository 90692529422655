import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DateUtils } from '../../utils';

const CustomTable = (props) => {
	const { period, series } = props;
	const { t, ready } = useTranslation(['components', 'date']);

	const [columns, setColumns] = useState([]);
	const [tableData, setTableData] = useState([]);

	useEffect(() => {
		generateTableContent();
	}, []);

	useEffect(() => {
		generateTableContent();
	}, [series]);

	const generateTableContent = () => {
		setColumns([]);
		setTableData([]);

		let tempColumns = [];
		let tempData = [];

		switch (period) {
			default:
				tempColumns = [
					{
						name: t('common:month'),
						selector: 'month',
						sortable: true,
					},
					{
						name: t('common:visits'),
						selector: 'visits',
						sortable: true,
					},
					{
						name: t('common:augmentations'),
						selector: 'augmentations',
						sortable: true,
					},
				];

				DateUtils.getMonths().map((month, index) => {
					tempData.push({
						month: t(`date:${month.full}`),
						visits: series[0][index],
						augmentations: series[1][index],
					});
				});
				break;
			case 'year':
				tempColumns = [
					{
						name: t('common:month'),
						selector: 'month',
						sortable: true,
					},
					{
						name: t('common:visits'),
						selector: 'visits',
						sortable: true,
					},
					{
						name: t('common:augmentations'),
						selector: 'augmentations',
						sortable: true,
					},
				];

				DateUtils.getMonths().map((month, index) => {
					tempData.push({
						month: t(`date:${month.full}`),
						visits: series[0][index],
						augmentations: series[1][index],
					});
				});
				break;
			case 'month':
				tempColumns = [
					{
						name: t('common:day'),
						selector: 'day',
						sortable: true,
					},
					{
						name: t('common:visits'),
						selector: 'visits',
						sortable: true,
					},
					{
						name: t('common:augmentations'),
						selector: 'augmentations',
						sortable: true,
					},
				];
				series[0].map((day, index) => {
					tempData.push({
						day: index + 1,
						visits: series[0][index],
						augmentations: series[1][index],
					});
				});
				break;
			case 'week':
				tempColumns = [
					{
						name: t('common:weekday'),
						selector: 'weekday',
						sortable: true,
					},
					{
						name: t('common:visits'),
						selector: 'visits',
						sortable: true,
					},
					{
						name: t('common:augmentations'),
						selector: 'augmentations',
						sortable: true,
					},
				];
				DateUtils.getWeekDays().map((day, index) => {
					tempData.push({
						weekday: t(`date:${day.short}`),
						visits: series[0][index],
						augmentations: series[1][index],
					});
				});
				break;
		}
		setColumns(tempColumns);
		setTableData(tempData);
	};

	return (
		<>
			{ready && (
				<DataTable
					columns={columns}
					data={tableData}
					title={t('data_table')}
					pagination={true}
					paginationPerPage={15}
				/>
			)}
		</>
	);
};

CustomTable.propTypes = {
	period: PropTypes.string,
	series: PropTypes.array,
};

CustomTable.defaultProps = {
	period: 'year',
	series: [],
};
export default CustomTable;
