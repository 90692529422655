import React from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export const TermsAndCondition = (props) => {
	const { t } = useTranslation(['terms', 'common']);

	return (
		<Card border="light" className="shadow-sm">
			<Card.Body>
				<h5>{t('common:terms_and_conditions')}</h5>
				<br></br>
				<div className="d-block" style={{ textAlign: 'justify' }}>
					<p>{t('disclaimer.1')}</p>
					<p>{t('disclaimer.2')}</p>
					<p>{t('disclaimer.3')}</p>
					<h5 className="mb-4">1. DERECHOS DE PROPIEDAD INTELECTUAL</h5>
					<p>
						A menos que se indique lo contrario, el Sitio es de nuestra
						propiedad y todo el código fuente, bases de datos, funcionalidad,
						software, diseños de sitios web, audio, video, texto, fotografías y
						gráficos en el Sitio (colectivamente, el "Contenido") y las marcas
						comerciales, servicio las marcas y los logotipos contenidos en ellos
						(las "Marcas") son de nuestra propiedad o están bajo nuestro control
						o tenemos licencia, y están protegidos por las leyes de derechos de
						autor y marcas registradas y varios otros derechos de propiedad
						intelectual y leyes de competencia desleal de los Estados Unidos,
						leyes internacionales de derechos de autor, y convenciones
						internacionales. El Contenido y las Marcas se proporcionan en el
						Sitio "TAL CUAL" para su información y uso personal únicamente.
						Salvo que se disponga expresamente en estos Términos de uso, ninguna
						parte del Sitio y ningún Contenido o Marcas pueden ser copiados,
						reproducidos, agregados, republicados, cargados, publicados,
						exhibidos públicamente, codificados, traducidos, transmitidos,
						distribuidos, vendidos, licenciados o explotados de otra manera para
						cualquier propósito comercial, sin nuestro permiso previo expreso
						por escrito. Siempre que sea elegible para usar el Sitio, se le
						otorga una licencia limitada para acceder y usar el Sitio y para
						descargar o imprimir una copia de cualquier parte del Contenido al
						que haya obtenido acceso de manera adecuada únicamente para su uso
						personal, no comercial. usar. Nos reservamos todos los derechos que
						no se le otorguen expresamente sobre el Sitio, el Contenido y las
						Marcas.
					</p>
					<h5 className="mb-4">2. REPRESENTACIONES DEL USUARIO</h5>
					<p>
						Al utilizar el Sitio, usted declara y garantiza que: (1) toda la
						información de registro que envíe será verdadera, precisa, actual y
						completa; (2) mantendrá la precisión de dicha información y
						actualizará de inmediato dicha información de registro según sea
						necesario; (3) tiene la capacidad legal y acepta cumplir con estos
						Términos de uso; (4) no es menor de edad en la jurisdicción en la
						que reside; (5) no accederá al Sitio a través de medios
						automatizados o no humanos, ya sea a través de un bot, script o de
						otro modo; (6) no utilizará el Sitio para ningún propósito ilegal o
						no autorizado; y (7) su uso del Sitio no violará ninguna ley o
						reglamento aplicable.
					</p>
					<p>
						Si proporciona información falsa, inexacta, no actual o incompleta,
						tenemos el derecho de suspender o cancelar su cuenta y rechazar
						cualquier uso actual o futuro del Sitio (o cualquier parte del
						mismo).
					</p>

					<h5 className="mb-4">3. REGISTRO DE USUARIO</h5>
					<p>
						Es posible que deba registrarse en el Sitio. Usted acepta mantener
						la confidencialidad de su contraseña y será responsable de todo uso
						de su cuenta y contraseña. Nos reservamos el derecho de eliminar,
						reclamar o cambiar un nombre de usuario que seleccione si
						determinamos, a nuestra entera discreción, que dicho nombre de
						usuario es inapropiado, obsceno o de otra manera objetable.
					</p>

					<h5 className="mb-4">4. PRODUCTOS</h5>
					<p>
						Todos los productos están sujetos a disponibilidad. Nos reservamos
						el derecho de descontinuar cualquier producto en cualquier momento y
						por cualquier motivo. Los precios de todos los productos están
						sujetos a cambios.
					</p>

					<h5 className="mb-4">5. COMPRAS Y PAGO</h5>
					<p>Aceptamos las siguientes formas de pago:</p>
					<p>- Visa</p>
					<p>- Mastercard</p>
					<p>- American Express</p>
					<p>- Discover</p>
					<p>- PayPal</p>
					<p>
						Usted acepta proporcionar información de compra y cuenta actual,
						completa y precisa para todas las compras realizadas a través del
						Sitio. Además, acepta actualizar de inmediato la información de
						cuenta y pago, incluida la dirección de correo electrónico, el
						método de pago y la fecha de vencimiento de la tarjeta de pago, para
						que podamos completar sus transacciones y comunicarnos con usted
						según sea necesario. El impuesto sobre las ventas se agregará al
						precio de las compras según lo consideremos necesario. Podemos
						cambiar los precios en cualquier momento. Todos los pagos se
						realizarán en dólares estadounidenses.
					</p>
					<p>
						Usted acepta pagar todos los cargos a los precios vigentes en ese
						momento para sus compras y cualquier tarifa de envío aplicable, y
						nos autoriza a cobrar a su proveedor de pago elegido por dichos
						montos al realizar su pedido. Si su pedido está sujeto a cargos
						recurrentes, entonces usted da su consentimiento para que carguemos
						su método de pago de manera recurrente sin requerir
					</p>
					<p>
						su aprobación previa para cada cargo recurrente, hasta el momento en
						que cancele el pedido correspondiente. Nos reservamos el derecho de
						corregir cualquier error o error en el precio, incluso si ya hemos
						solicitado o recibido el pago. Nos reservamos el derecho a rechazar
						cualquier pedido realizado a través del Sitio. Podemos, a nuestro
						exclusivo criterio, limitar o cancelar las cantidades compradas por
						persona, por hogar o por pedido. Estas restricciones pueden incluir
						pedidos realizados por o bajo la misma cuenta de cliente, el mismo
						método de pago y / o pedidos que utilizan la misma dirección de
						facturación o envío. Nos reservamos el derecho de limitar o prohibir
						los pedidos que, a nuestro exclusivo criterio, parecen ser
						realizados por concesionarios, revendedores o distribuidores.
					</p>

					<h5 className="mb-4">6. POLÍTICA DE REEMBOLSOS</h5>
					<p>Todas las ventas son definitivas y no se emitirán reembolsos.</p>

					<h5 className="mb-4">7. ACTIVIDADES PROHIBIDAS</h5>
					<p>
						No puede acceder ni utilizar el Sitio para ningún otro propósito que
						no sea el que lo ponemos a disposición. El Sitio no se puede
						utilizar en relación con ningún esfuerzo comercial, excepto aquellos
						que son específicamente respaldados o aprobados por nosotros.
					</p>
					<p>
						1. Recuperar sistemáticamente datos u otro contenido del Sitio para
						crear o compilar, directa o indirectamente, una colección,
						compilación, base de datos o directorio sin nuestro permiso por
						escrito.{' '}
					</p>
					<p>
						2. Hacer cualquier uso no autorizado del Sitio, incluida la
						recopilación de nombres de usuario y / o direcciones de correo
						electrónico de los usuarios por medios electrónicos o de otro tipo
						con el fin de enviar correos electrónicos no solicitados, o crear
						cuentas de usuario por medios automatizados o con falsas
						pretensiones.
					</p>
					<p>
						3. Utilice el Sitio para publicitar u ofrecer vender bienes y
						servicios.
					</p>
					<p>
						4. Eludir, deshabilitar o interferir de otro modo con las funciones
						del Sitio relacionadas con la seguridad, incluidas las funciones que
						impiden o restringen el uso o la copia de cualquier Contenido o
						imponen limitaciones en el uso del Sitio y / o el Contenido que
						contiene.{' '}
					</p>
					<p>5. Participar en encuadres no autorizados o enlaces al Sitio. </p>
					<p>
						6. Engañarnos, defraudarnos o engañarnos a nosotros ya otros
						usuarios, especialmente en cualquier intento de obtener información
						confidencial de la cuenta, como las contraseñas de los usuarios.
					</p>
					<p>
						7. Hacer un uso indebido de nuestros servicios de soporte o enviar
						informes falsos de abuso o mala conducta.{' '}
					</p>
					<p>
						8. Participar en cualquier uso automatizado del sistema, como el uso
						de scripts para enviar comentarios o mensajes, o el uso de minería
						de datos, robots o herramientas similares de recopilación y
						extracción de datos.{' '}
					</p>
					<p>
						9. Interferir, interrumpir o crear una carga indebida en el Sitio o
						las redes o servicios conectados al Sitio.{' '}
					</p>
					<p>
						10. Intentar hacerse pasar por otro usuario o persona o utilizar el
						nombre de usuario de otro usuario.{' '}
					</p>
					<p>11. Vender o transferir su perfil. </p>
					<p>
						12. Usar cualquier información obtenida del Sitio para acosar,
						abusar o dañar a otra persona.
					</p>
					<p>
						13. Usar el Sitio como parte de cualquier esfuerzo para competir con
						nosotros o usar el Sitio y / o el Contenido para cualquier esfuerzo
						de generación de ingresos o empresa comercial.{' '}
					</p>
					<p>
						14. Descifrar, descompilar, desensamblar o aplicar ingeniería
						inversa a cualquier software que comprenda o de alguna manera forme
						parte del Sitio.{' '}
					</p>
					<p>
						15. Intente eludir cualquier medida del Sitio diseñada para prevenir
						o restringir el acceso al Sitio, o cualquier parte del Sitio.{' '}
					</p>
					<p>
						16. Acosar, molestar, intimidar o amenazar a cualquiera de nuestros
						empleados o agentes que le proporcionen cualquier parte del Sitio.{' '}
					</p>
					<p>
						17. Copie o adapte el software del Sitio, incluidos, entre otros,
						Flash, PHP, HTML, JavaScript u otro código.{' '}
					</p>
					<p>
						18. Elimine el aviso de derechos de autor u otros derechos de
						propiedad de cualquier Contenido.{' '}
					</p>
					<p>
						19. Cargar o transmitir (o intentar cargar o transmitir) virus,
						caballos de Troya u otro material, incluido el uso excesivo de
						letras mayúsculas y correo no deseado (publicación continua de texto
						repetitivo), que interfiere con el uso y disfrute ininterrumpidos de
						la Sitio o modifica, deteriora, interrumpe, altera o interfiere con
						el uso, características, funciones, operación o mantenimiento del
						Sitio.{' '}
					</p>
					<p>
						20. Cargar o transmitir (o intentar cargar o transmitir) cualquier
						material que actúe como mecanismo de transmisión o recopilación de
						información pasiva o activa, incluidos, entre otros, formatos de
						intercambio de gráficos claros ("gifs"), píxeles de 1 × 1, errores
						web , cookies u otros dispositivos similares (a veces denominados
						"software espía" o "mecanismos de recopilación pasiva" o "pcms").{' '}
					</p>
					<p>
						21. Excepto que sea el resultado del uso de un motor de búsqueda
						estándar o un navegador de Internet, use, inicie, desarrolle o
						distribuya cualquier sistema automatizado, incluidos, entre otros,
						cualquier araña, robot, utilidad de trampa, raspador o lector fuera
						de línea que acceda al Sitio. , o usar o ejecutar cualquier script u
						otro software no autorizado.
					</p>
					<p>
						22. Despreciar, empañar o dañar, en nuestra opinión, a nosotros y /
						o al Sitio.{' '}
					</p>
					<p>
						23. Utilice el Sitio de una manera incompatible con las leyes o
						regulaciones aplicables.{' '}
					</p>
					<p>
						24. Utilice un agente de compras o un agente de compras para
						realizar compras en el Sitio.
					</p>

					<h5 className="mb-4">8. CONTRIBUCIONES GENERADAS POR EL USUARIO</h5>
					<p>
						El Sitio no ofrece a los usuarios enviar o publicar contenido.
						Podemos brindarle la oportunidad de crear, enviar, publicar,
						mostrar, transmitir, interpretar, publicar, distribuir o transmitir
						contenido y materiales a nosotros o en el Sitio, incluidos, entre
						otros, texto, escritos, video, audio, fotografías. , gráficos,
						comentarios, sugerencias o información personal u otro material
						(colectivamente, "Contribuciones"). Las contribuciones pueden ser
						visibles para otros usuarios del Sitio y a través de sitios web de
						terceros. Como tal, cualquier Contribución que transmita puede
						tratarse de acuerdo con la Política de privacidad del sitio. Cuando
						crea o pone a disposición cualquier Contribución, usted declara y
						garantiza que:
					</p>
					<p>
						1. La creación, distribución, transmisión, exhibición pública o
						ejecución, y el acceso, la descarga o la copia de sus Contribuciones
						no infringen ni infringirán los derechos de propiedad, incluidos,
						entre otros, los derechos de autor, patentes, marcas comerciales,
						secretos comerciales. , o derechos morales de cualquier tercero.{' '}
					</p>
					<p>
						2. Usted es el creador y propietario o tiene las licencias, los
						derechos, los consentimientos, las liberaciones y los permisos
						necesarios para usar y autorizarnos a nosotros, al Sitio y a otros
						usuarios del Sitio a usar sus Contribuciones de cualquier manera
						contemplada por el Sitio. y estos Términos de uso.{' '}
					</p>
					<p>
						3. Tiene el consentimiento, la liberación y / o el permiso por
						escrito de todas y cada una de las personas identificables en sus
						Contribuciones para usar el nombre o la imagen de todas y cada una
						de esas personas identificables para permitir la inclusión y el uso
						de sus Contribuciones de cualquier manera contemplada. por el Sitio
						y estos Términos de uso.{' '}
					</p>
					<p>4. Sus Contribuciones no son falsas, inexactas o engañosas. </p>
					<p>
						5. Sus Contribuciones no son publicidad no solicitada o no
						autorizada, materiales promocionales, esquemas piramidales, cartas
						en cadena, correo no deseado, correos masivos u otras formas de
						solicitud.{' '}
					</p>
					<p>
						6. Sus Contribuciones no son obscenas, lascivas, lascivas, sucias,
						violentas, acosadoras, difamatorias, difamatorias u objetables
						(según lo determinemos).{' '}
					</p>
					<p>
						7. Sus Contribuciones no ridiculizan, mofan, menosprecian, intimidan
						ni abusan de nadie.{' '}
					</p>
					<p>
						8. Sus Contribuciones no se utilizan para acosar o amenazar (en el
						sentido legal de esos términos) a ninguna otra persona y para
						promover la violencia contra una persona o clase de personas
						específicas.{' '}
					</p>
					<p>
						9. Sus Contribuciones no violan ninguna ley, regulación o regla
						aplicable.{' '}
					</p>
					<p>
						10. Sus Contribuciones no violan los derechos de privacidad o
						publicidad de ningún tercero.{' '}
					</p>
					<p>
						11. Sus Contribuciones no contienen ningún material que solicite
						información personal de personas menores de 18 años o explote a
						personas menores de 18 años de manera sexual o violenta.{' '}
					</p>
					<p>
						12. Sus Contribuciones no violan ninguna ley aplicable relacionada
						con la pornografía infantil, ni tienen la intención de proteger la
						salud o el bienestar de los menores de edad.{' '}
					</p>
					<p>
						13. Sus Contribuciones no incluyen ningún comentario ofensivo
						relacionado con la raza, el origen nacional, el género, la
						preferencia sexual o la discapacidad física.{' '}
					</p>
					<p>
						14. Sus Contribuciones no violan ni vinculan a material que viole
						ninguna disposición de estos Términos de uso ni ninguna ley o
						reglamento aplicable.{' '}
					</p>
					<p>
						Cualquier uso del Sitio o de las Ofertas del Mercado en violación de
						lo anterior viola estos Términos de uso y puede resultar, entre
						otras cosas, en la terminación o suspensión de sus derechos para
						usar el Sitio y las Ofertas del Mercado.{' '}
					</p>

					<h5 className="mb-4">9. LICENCIA DE CONTRIBUCIÓN</h5>
					<p>
						Usted y el Sitio acuerdan que podemos acceder, almacenar, procesar y
						utilizar cualquier información y datos personales que proporcione
						siguiendo los términos de la Política de privacidad y sus opciones
						(incluida la configuración).
					</p>
					<p>
						Al enviar sugerencias u otros comentarios con respecto al Sitio,
						acepta que podemos usar y compartir dichos comentarios para
						cualquier propósito sin compensación para usted.{' '}
					</p>
					<p>
						No afirmamos ninguna propiedad sobre sus Contribuciones. Usted
						conserva la propiedad total de todas sus Contribuciones y cualquier
						derecho de propiedad intelectual u otros derechos de propiedad
						asociados con sus Contribuciones. No somos responsables de ninguna
						declaración o representación en sus Contribuciones proporcionada por
						usted en cualquier área del Sitio. Usted es el único responsable de
						sus Contribuciones al Sitio y acepta expresamente exonerarnos de
						toda responsabilidad y abstenerse de cualquier acción legal contra
						nosotros con respecto a sus Contribuciones.{' '}
					</p>

					<h5 className="mb-4">10. ENVÍOS</h5>
					<p>
						Usted reconoce y acepta que cualquier pregunta, comentario,
						sugerencia, idea, comentario u otra información con respecto al
						Sitio o las Ofertas del Mercado ("Envíos") que nos proporcione no
						son confidenciales y pasarán a ser de nuestra exclusiva propiedad.
						Seremos dueños de los derechos exclusivos, incluidos todos los
						derechos de propiedad intelectual, y tendremos derecho al uso y
						difusión sin restricciones de estos Envíos para cualquier propósito
						legal, comercial o de otro tipo, sin reconocimiento o compensación
						para usted. Por la presente, renuncia a todos los derechos morales
						sobre dichos Envíos y, por la presente, garantiza que dichos Envíos
						son originales para usted o que tiene derecho a enviar dichos
						Envíos. Usted acepta que no habrá ningún recurso contra nosotros por
						cualquier infracción o apropiación indebida presunta o real de
						cualquier derecho de propiedad en sus Envíos.
					</p>

					<h5 className="mb-4">11. SITIOS WEB Y CONTENIDO DE TERCEROS</h5>
					<p>
						El Sitio puede contener (o puede ser enviado a través del Sitio o
						las Ofertas del Mercado) enlaces a otros sitios web ("Sitios web de
						terceros"), así como artículos, fotografías, texto, gráficos,
						imágenes, diseños, música, sonido, video. , información,
						aplicaciones, software y otro contenido o elementos que pertenezcan
						o se originen en terceros ("Contenido de terceros"). Dichos Sitios
						web de terceros y Contenido de terceros no son investigados,
						monitoreados ni verificados para su precisión, idoneidad o
						integridad, y no somos responsables de los Sitios web de terceros a
						los que se acceda a través del Sitio o de cualquier Contenido de
						terceros publicado en , disponible a través del Sitio o instalado
						desde el mismo, incluido el contenido, la precisión, el carácter
						ofensivo, las opiniones, la confiabilidad, las prácticas de
						privacidad u otras políticas de o contenidas en los Sitios web de
						terceros o el Contenido de terceros. La inclusión, la vinculación o
						el permitir el uso o la instalación de cualquier sitio web de
						terceros o cualquier contenido de terceros no implica la aprobación
						o aprobación de los mismos por nuestra parte. Si decide abandonar el
						Sitio y acceder a los Sitios web de terceros o utilizar o instalar
						Contenido de terceros, lo hace bajo su propio riesgo y debe tener en
						cuenta que estos Términos de uso ya no rigen. Debe revisar los
						términos y políticas aplicables, incluidas las prácticas de
						privacidad y recopilación de datos, de cualquier sitio web al que
						navegue desde el Sitio o en relación con cualquier aplicación que
						use o instale desde el Sitio. Cualquier compra que realice a través
						de sitios web de terceros se realizará a través de otros sitios web
						y de otras empresas, y no asumimos responsabilidad alguna en
						relación con dichas compras, que son exclusivamente entre usted y el
						tercero correspondiente. Usted acepta y reconoce que no respaldamos
						los productos o servicios ofrecidos en los sitios web de terceros y
						nos eximirá de cualquier daño causado por la compra de dichos
						productos o servicios. Además, nos eximirá de toda responsabilidad
						por cualquier pérdida sufrida por usted o daño que le haya causado
						en relación con el Contenido de terceros o que resulte de cualquier
						manera de cualquier Contenido de terceros o cualquier contacto con
						Sitios web de terceros.
					</p>

					<h5 className="mb-4">12. MANEJO DE SITIO</h5>
					<p>
						Nos reservamos el derecho, pero no la obligación, de: (1) monitorear
						el Sitio para detectar violaciones de estos Términos de uso; (2)
						tomar las acciones legales apropiadas contra cualquier persona que,
						a nuestro exclusivo criterio, viole la ley o estos Términos de uso,
						incluyendo, sin limitación, informar a dicho usuario a las
						autoridades policiales; (3) a nuestro exclusivo criterio y sin
						limitación, rechazar, restringir el acceso, limitar la
						disponibilidad o deshabilitar (en la medida tecnológicamente
						posible) cualquiera de sus Contribuciones o cualquier parte de las
						mismas; (4) a nuestro exclusivo criterio y sin limitación, aviso o
						responsabilidad, para eliminar del Sitio o deshabilitar todos los
						archivos y contenido que sean de tamaño excesivo o que sean de
						alguna manera una carga para nuestros sistemas; y (5) de otra manera
						administrar el Sitio de una manera diseñada para proteger nuestros
						derechos y propiedad y para facilitar el funcionamiento adecuado del
						Sitio y las Ofertas del Mercado.{' '}
					</p>

					<h5 className="mb-4">13. POLÍTICA DE PRIVACIDAD</h5>
					<p>
						Al utilizar el Sitio o las Ofertas de Marketplace, acepta regirse
						por nuestra Política de privacidad, que se incorpora a estos
						Términos de uso. Tenga en cuenta que el sitio y las ofertas del
						mercado están alojados en los Estados Unidos. Si accede al Sitio o
						las Ofertas del Mercado desde cualquier otra región del mundo con
						leyes u otros requisitos que rigen la recopilación, el uso o la
						divulgación de datos personales que difieren de las leyes aplicables
						en los Estados Unidos, entonces a través de su uso continuo del
						Sitio, usted está transfiriendo sus datos a los Estados Unidos, y
						usted da su consentimiento expreso para que sus datos sean
						transferidos y procesados en los Estados Unidos.{' '}
					</p>

					<h5 className="mb-4">14. DURACIÓN Y TERMINACIÓN</h5>
					<p>
						Estos Términos de uso permanecerán en pleno vigor y efecto mientras
						utiliza el Sitio. SIN LIMITAR NINGUNA OTRA DISPOSICIÓN DE ESTOS
						TÉRMINOS DE USO, NOS RESERVAMOS EL DERECHO A, A NUESTRA ÚNICA
						DISCRECIÓN Y SIN PREVIO AVISO O RESPONSABILIDAD, NEGAR EL ACCESO Y
						EL USO DEL SITIO Y LAS OFERTAS DEL MERCADO (INCLUYENDO EL BLOQUEO DE
						CIERTAS PERSONAS DE IP) POR CUALQUIER RAZÓN O SIN RAZÓN, INCLUYENDO
						SIN LIMITACIÓN POR INCUMPLIMIENTO DE CUALQUIER REPRESENTACIÓN,
						GARANTÍA O PACTO CONTENIDO EN ESTOS TÉRMINOS DE USO O DE CUALQUIER
						LEY O REGLAMENTO APLICABLE. PODEMOS TERMINAR SU USO O PARTICIPACIÓN
						EN EL SITIO Y LAS OFERTAS DE MARKETPLACE O BORRAR SU CUENTA Y
						CUALQUIER CONTENIDO O INFORMACIÓN QUE USTED PUBLICÓ EN CUALQUIER
						MOMENTO, SIN ADVERTENCIA, A NUESTRA ÚNICA DISCRECIÓN{' '}
					</p>
					<p>
						Si cancelamos o suspendemos su cuenta por cualquier motivo, tiene
						prohibido registrarse y crear una nueva cuenta con su nombre, un
						nombre falso o prestado, o el nombre de un tercero, incluso si puede
						estar actuando en nombre del tercero. fiesta. Además de cancelar o
						suspender su cuenta, nos reservamos el derecho de emprender las
						acciones legales apropiadas, incluidas, entre otras, la búsqueda de
						una reparación civil, penal y por mandato judicial.
					</p>

					<h5 className="mb-4">15. MODIFICACIONES E INTERRUPCIONES</h5>
					<p>
						Nos reservamos el derecho de cambiar, modificar o eliminar el
						contenido del Sitio en cualquier momento o por cualquier motivo a
						nuestro exclusivo criterio sin previo aviso. Sin embargo, no tenemos
						la obligación de actualizar ninguna información en nuestro sitio.
						También nos reservamos el derecho de modificar o descontinuar la
						totalidad o parte de las Ofertas del Mercado sin previo aviso en
						cualquier momento. No seremos responsables ante usted ni ante ningún
						tercero por ninguna modificación, cambio de precio, suspensión o
						interrupción del Sitio o de las Ofertas del Mercado.{' '}
					</p>
					<p>
						No podemos garantizar que el Sitio y las Ofertas del Mercado estén
						disponibles en todo momento. Es posible que experimentemos hardware,
						software u otros problemas o que necesitemos realizar un
						mantenimiento relacionado con el Sitio, lo que resultará en
						interrupciones, retrasos o errores. Nos reservamos el derecho de
						cambiar, revisar, actualizar, suspender, descontinuar o modificar el
						Sitio o las Ofertas del Mercado en cualquier momento o por cualquier
						motivo sin notificárselo. Usted acepta que no tenemos
						responsabilidad alguna por cualquier pérdida, daño o inconveniente
						causado por su incapacidad para acceder o utilizar el Sitio o las
						Ofertas del Mercado durante cualquier tiempo de inactividad o
						interrupción del Sitio o las Ofertas del Mercado. Nada en estos
						Términos de uso se interpretará en el sentido de que nos obligue a
						mantener y respaldar el Sitio o las Ofertas del Mercado ni a
						proporcionar correcciones, actualizaciones o comunicados en relación
						con los mismos.{' '}
					</p>

					<h5 className="mb-4">16. LEY QUE RIGE</h5>
					<p>
						Estos Términos de uso y su uso del Sitio y las Ofertas del Mercado
						se rigen e interpretan de acuerdo con las leyes del Estado de
						Illinois aplicables a los acuerdos realizados y que se ejecutarán en
						su totalidad dentro del Estado de Illinois, sin tener en cuenta su
						conflicto de principios de la ley.{' '}
					</p>

					<h5 className="mb-4">17. RESOLUCIÓN DE CONFLICTOS</h5>
					<p>
						Cualquier acción legal de cualquier naturaleza iniciada por usted o
						nosotros (colectivamente, las "Partes" e individualmente, una
						"Parte") se iniciará o enjuiciará en los tribunales estatales y
						federales ubicados en Cook, Illinois, y las Partes por el presente
						dan su consentimiento para y renunciar a todas las defensas de falta
						de jurisdicción personal y forum non conveniens con respecto al
						lugar y jurisdicción en dichos tribunales estatales y federales. La
						aplicación de la Convención de las Naciones Unidas sobre Contratos
						de Compraventa Internacional de Mercaderías y la Ley Uniforme de
						Transacciones de Información Computarizada (UCITA) están excluidas
						de estos Términos de Uso. En ningún caso, cualquier reclamo, acción
						o procedimiento entablado por cualquiera de las Partes relacionado
						de alguna manera con el Sitio se iniciará más de un (1) año después
						de que surgió la causa de la acción.
					</p>

					<h5 className="mb-4">18. CORRECCIONES</h5>
					<p>
						Puede haber información en el Sitio que contenga errores
						tipográficos, inexactitudes u omisiones que puedan estar
						relacionadas con las Ofertas del Mercado, incluidas descripciones,
						precios, disponibilidad y otra información diversa. Nos reservamos
						el derecho de corregir cualquier error, inexactitud u omisión y de
						cambiar o actualizar la información en el Sitio en cualquier
						momento, sin previo aviso.{' '}
					</p>

					<h5 className="mb-4">19. DESCARGO DE RESPONSABILIDAD</h5>
					<p>
						EL SITIO SE PROPORCIONA TAL COMO ESTÁ Y SEGÚN ESTÉ DISPONIBILIDAD.
						USTED ACEPTA QUE EL USO DE LOS SERVICIOS DEL SITIO SERÁ BAJO SU
						PROPIO RIESGO. HASTA EL GRADO MÁXIMO PERMITIDO POR LA LEY, NEGAMOS
						TODAS LAS GARANTÍAS, EXPRESAS O IMPLÍCITAS, EN RELACIÓN CON EL SITIO
						Y SU USO DEL MISMO, INCLUYENDO, SIN LIMITACIÓN, LAS GARANTÍAS
						IMPLÍCITAS DE COMERCIABILIDAD, ADECUACIÓN PARA UN PROPÓSITO DE
						INFRACCIÓN PARTICULAR. NO OFRECEMOS GARANTÍAS NI DECLARACIONES
						ACERCA DE LA EXACTITUD O INTEGRIDAD DEL CONTENIDO DEL SITIO O EL
						CONTENIDO DE CUALQUIER SITIO WEB VINCULADO A ESTE SITIO Y NO
						ASUMIMOS NINGUNA RESPONSABILIDAD POR CUALQUIER (1) ERROR, ERROR O
						MATERIAL DE CONTENIDO INEXACTO ( 2) LESIONES PERSONALES O DAÑOS A LA
						PROPIEDAD, DE CUALQUIER NATURALEZA, QUE RESULTEN DE SU ACCESO Y USO
						DEL SITIO, (3) CUALQUIER ACCESO O USO NO AUTORIZADO DE NUESTROS
						SERVIDORES SEGUROS Y / O CUALQUIER Y TODA LA INFORMACIÓN PERSONAL Y
						/ O INFORMACIÓN FINANCIERA ALMACENADO EN EL MISMO, (4) CUALQUIER
						INTERRUPCIÓN O CESE DE LA TRANSMISIÓN HACIA O DESDE EL SITIO, (5)
						CUALQUIER ERROR, VIRUS, CABALLO TROYANO, O LO QUE SE PUEDE
						TRANSMITIR AL SITIO O A TRAVÉS DEL SITIO POR CUALQUIER TERCERO, Y /
						O ( 6) CUALQUIER ERROR U OMISIÓN EN CUALQUIER CONTENIDO Y MATERIALES
						O POR CUALQUIER PÉRDIDA O DAÑO DE CUALQUIER TIPO INCURRIDO COMO
						RESULTADO DEL USO DE CUALQUIER CONTENIDO PUBLICADO, TRANSMITIDO O
						DISPONIBLE DE OTRO MODO A TRAVÉS DEL SITIO. NO GARANTIZAMOS,
						APROBAMOS, GARANTIZAMOS O ASUMIMOS RESPONSABILIDAD POR NINGÚN
						PRODUCTO O SERVICIO PUBLICITADO U OFRECIDO POR UN TERCERO A TRAVÉS
						DEL SITIO, CUALQUIER SITIO WEB HIPERVINCULADO O CUALQUIER SITIO WEB
						O APLICACIÓN MÓVIL QUE SE PRESENTA EN CUALQUIER BANNER U OTRO
						PUBLICIDAD SER UNA PARTE O DE ALGUNA MANERA SER RESPONSABLE DE
						MONITOREAR CUALQUIER TRANSACCIÓN ENTRE USTED Y CUALQUIER TERCERO
						PROVEEDOR DE PRODUCTOS O SERVICIOS. COMO CON LA COMPRA DE UN
						PRODUCTO O SERVICIO A TRAVÉS DE CUALQUIER MEDIO O EN CUALQUIER
						AMBIENTE, DEBE USAR SU MEJOR JUICIO Y TENER PRECAUCIÓN DONDE SEA
						APROPIADO.{' '}
					</p>

					<h5 className="mb-4">20. LIMITACIONES DE RESPONSABILIDAD</h5>
					<p>
						EN NINGÚN CASO NOSOTROS O NUESTROS DIRECTORES, EMPLEADOS O AGENTES
						SEREMOS RESPONSABLES ANTE USTED O CUALQUIER TERCERO POR CUALQUIER
						DAÑO DIRECTO, INDIRECTO, CONSECUENTE, EJEMPLAR, INCIDENTAL, ESPECIAL
						O PUNITIVO, INCLUYENDO PÉRDIDA DE BENEFICIOS, PÉRDIDA DE INGRESOS,
						PÉRDIDA DE DATOS, U OTROS DAÑOS QUE SURJAN DE SU USO DEL SITIO,
						INCLUSO SI HEMOS SIDO ADVERTIDOS DE LA POSIBILIDAD DE DICHOS DAÑOS.
						INDEPENDIENTEMENTE DE LO CONTRARIO CONTENIDO EN ESTE DOCUMENTO,
						NUESTRA RESPONSABILIDAD ANTE USTED POR CUALQUIER CAUSA Y
						INDEPENDIENTEMENTE DE LA FORMA DE LA ACCIÓN, SE LIMITARÁ EN TODO
						MOMENTO A LA CANTIDAD PAGADA, SI LA HAY, POR USTED DURANTE EL MES
						PERÍODO ANTERIOR A CUALQUIER CAUSA DE ACCIÓN QUE SURJA. CIERTAS
						LEYES ESTATALES DE EE. UU. Y LEYES INTERNACIONALES NO PERMITEN
						LIMITACIONES DE GARANTÍAS IMPLÍCITAS O LA EXCLUSIÓN O LIMITACIÓN DE
						CIERTOS DAÑOS. SI ESTAS LEYES SE APLICAN EN SU CASO, ALGUNAS O TODAS
						LAS EXENCIONES O LIMITACIONES ANTERIORES PUEDEN NO APLICARSE EN SU
						CASO, Y ES POSIBLE QUE USTED TENGA DERECHOS ADICIONALES.{' '}
					</p>

					<h5 className="mb-4">21. INDEMNIZACIÓN</h5>
					<p>
						Usted acepta defender, indemnizar y eximirnos de responsabilidad,
						incluidas nuestras subsidiarias, afiliadas y todos nuestros
						respectivos funcionarios, agentes, socios y empleados, de y contra
						cualquier pérdida, daño, responsabilidad, reclamo o demanda,
						incluidos abogados razonables. 'honorarios y gastos, realizados por
						terceros debido a o que surjan de: (1) el uso del Sitio; (2)
						incumplimiento de estos Términos de uso; (3) cualquier
						incumplimiento de sus declaraciones y garantías establecidas en
						estos Términos de uso; (4) su violación de los derechos de un
						tercero, incluidos, entre otros, los derechos de propiedad
						intelectual; o (5) cualquier acto dañino manifiesto hacia cualquier
						otro usuario del Sitio con el que se conectó a través del Sitio. Sin
						perjuicio de lo anterior, nos reservamos el derecho, a su cargo, de
						asumir la defensa y el control exclusivos de cualquier asunto por el
						que deba indemnizarnos, y usted acepta cooperar, a su cargo, con
						nuestra defensa de dichos reclamos. Haremos todos los esfuerzos
						razonables para notificarle sobre cualquier reclamo, acción o
						procedimiento que esté sujeto a esta indemnización cuando tengamos
						conocimiento de ello.{' '}
					</p>

					<h5 className="mb-4">22. DATOS DEL USUARIO</h5>
					<p>
						Conservaremos ciertos datos que transmita al Sitio con el fin de
						administrar el rendimiento del Sitio, así como los datos
						relacionados con su uso del Sitio. Aunque realizamos copias de
						seguridad de datos de forma periódica, usted es el único responsable
						de todos los datos que transmita o que se relacionen con cualquier
						actividad que haya realizado utilizando el Sitio. Usted acepta que
						no seremos responsables ante usted por cualquier pérdida o
						corrupción de dichos datos, y por la presente renuncia a cualquier
						derecho de acción contra nosotros que surja de dicha pérdida o
						corrupción de dichos datos.{' '}
					</p>

					<h5 className="mb-4">
						23. COMUNICACIONES, TRANSACCIONES Y FIRMAS ELECTRÓNICAS
					</h5>
					<p>
						Visitar el Sitio, enviarnos correos electrónicos y completar
						formularios en línea constituyen comunicaciones electrónicas. Usted
						da su consentimiento para recibir comunicaciones electrónicas y
						acepta que todos los acuerdos, avisos, divulgaciones y otras
						comunicaciones que le proporcionamos electrónicamente, por correo
						electrónico y en el Sitio, satisfacen cualquier requisito legal de
						que dicha comunicación sea por escrito. USTED ACEPTA EL USO DE
						FIRMAS, CONTRATOS, PEDIDOS Y OTROS REGISTROS ELECTRÓNICOS, Y LA
						ENTREGA ELECTRÓNICA DE AVISOS, POLÍTICAS Y REGISTROS DE
						TRANSACCIONES INICIADAS O COMPLETADAS POR NOSOTROS O A TRAVÉS DEL
						SITIO. Por la presente renuncia a cualquier derecho o requisito bajo
						cualquier estatuto, reglamento, regla, ordenanza u otras leyes en
						cualquier jurisdicción que requieran una firma original o la entrega
						o retención de registros no electrónicos, o para pagos o la
						concesión de créditos por cualquier medio distinto. que los medios
						electrónicos.{' '}
					</p>

					<h5 className="mb-4">24. MISCELANEOS</h5>
					<p>
						Estos Términos de uso y cualquier política o regla operativa
						publicada por nosotros en el Sitio o con respecto al Sitio
						constituyen el acuerdo completo y el entendimiento entre usted y
						nosotros. El hecho de que no ejerzamos o hagamos cumplir cualquier
						derecho o disposición de estos Términos de uso no constituirá una
						renuncia a dicho derecho o disposición. Estos Términos de uso operan
						en la mayor medida permitida por la ley. Podemos ceder alguno o
						todos nuestros derechos y obligaciones a otros en cualquier momento.
						No seremos responsables por ninguna pérdida, daño, demora o falta de
						actuación causados por cualquier causa fuera de nuestro control
						razonable. Si alguna disposición o parte de una disposición de estos
						Términos de uso se determina que es ilegal, nula o inaplicable, esa
						disposición o parte de la disposición se considerará separable de
						estos Términos de uso y no afectará la validez y aplicabilidad de
						los restantes. provisiones. No existe una relación de empresa
						conjunta, sociedad, empleo o agencia creada entre usted y nosotros
						como resultado de estos Términos de uso o el uso del Sitio. Usted
						acepta que estos Términos de uso no se interpretarán en nuestra
						contra en virtud de haberlos redactado. Por la presente, renuncia a
						todas y cada una de las defensas que pueda tener basadas en la forma
						electrónica de estos Términos de uso y la falta de firma por parte
						de las partes para ejecutar estos Términos de uso.
					</p>

					<h5 className="mb-4">25. CONTÁCTENOS</h5>
					<p>AppAR SPA. </p>
					<p>Avda. La Dehesa 181 Of. 809 </p>
					<p>Lo Barnechea, Santiago de Chile </p>
					<p>contacto@appar.io</p>
				</div>
			</Card.Body>
		</Card>
	);
};

export default TermsAndCondition;
