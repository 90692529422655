import axios from 'axios';
import {
	Message,
	Payment,
	Product,
	RequestCallback,
	User,
} from 'typings/custom';

export const getUsers = (callback: RequestCallback) => async () => {
	await axios
		.get('/admin/users')
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};

export const getAllStores = (query: String, callback: RequestCallback) => async () => {
	await axios
		.get('/admin/stores?' + query)
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};

export const getGlobalStats = (callback: RequestCallback) => async () => {
	await axios
		.get('/admin/stats')
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};

export const getSpecificStats =
	(query: string, callback: RequestCallback) => async () => {
		await axios
			.get(`/admin/stats/detail?${query}`)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const createUser =
	(user: User, callback: RequestCallback) => async () => {
		await axios
			.post('/admin/users', user)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const updateUser =
	(user: User, callback: RequestCallback) => async () => {
		await axios
			.put(`/admin/users/${user.user_id}`, user)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const getPayments =
	(user_id: ID, callback: RequestCallback) => async () => {
		await axios
			.get('/admin/payment/' + user_id)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const createPayment =
	(payment: Payment, callback: RequestCallback) => async () => {
		await axios
			.post('/admin/payment/', payment)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const editPayment =
	(payment: Payment, callback: RequestCallback) => async () => {
		await axios
			.put(`/admin/payment/${payment.payment_id}`, payment)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const sendGlobalMessage =
	(message: Message, callback: RequestCallback) => async () => {
		await axios
			.post('/admin/message/', message)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const getMessagesSended = (callback: RequestCallback) => async () => {
	await axios
		.get('/admin/message/')
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};

export const getStoreProducts =
	(id: ID, callback: RequestCallback) => async () => {
		await axios
			.get('/admin/store/' + id + '/products')
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const getProducts =
	(query: String, callback: RequestCallback) => async () => {
		await axios
			.get('/admin/products?' + query)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const getProductStats =
	(id: ID, query: string, callback: RequestCallback) => async () => {
		await axios
			.get(`/admin/stats/product/${id}?${query}`)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const assignProduct =
	(id: ID, callback: RequestCallback) => async () => {
		await axios
			.post('/admin/product/' + id)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const transferProduct =
	(product: Product, callback: RequestCallback) => async () => {
		await axios
			.put('/admin/product/', product)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const loginAs = (id: ID, callback: RequestCallback) => async () => {
	await axios
		.get('/admin/user/' + id + '/auth')
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};

export const getApiLogs =
	(query: string, callback: RequestCallback) => async () => {
		await axios
			.get(`/logs?${query}`)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const getApiLog =
	(date: string, callback: RequestCallback) => async () => {
		await axios
			.get(`/logs/${date}`)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const getTopProducts =
	(query: string, callback: RequestCallback) => async () => {
		await axios
			.get('/admin/top?' + query)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const shareProduct =
	(body: Object, callback: RequestCallback) => async () => {
		await axios
			.post('/admin/product/share', body)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};