import axios from 'axios';

export const linkStore =
	(code: any, store_id: any, callback: any) => async () => {
		await axios
			.post(process.env.REACT_APP_APPAR_PUBLIC_API + 'tiendanube/auth', {
				code: code,
				store_id: store_id,
			})
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const getProducts =
	(store_id: any, page: any, per_page: any, query: any, callback: any) =>
	async () => {
		await axios
			.get(
				process.env.REACT_APP_APPAR_PUBLIC_API +
					`tiendanube/store/${store_id}/products?page=${page}&per_page=${per_page}&q=${query}`
			)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const createAndLink = (code: any, lang: string, callback: any) => async () => {
	await axios
		.post(`/user/tiendanube?lang=${lang}`, { code: code })
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};

export const getStoreData = async (storeId: ID) => {
	return await axios.get(
		process.env.REACT_APP_APPAR_PUBLIC_API + `tiendanube/store/${storeId}`
	);
};
