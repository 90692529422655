import axios from 'axios';
import { Product, RequestCallback } from 'typings/custom';

export const deleteProduct =
	(id: ID, callback: RequestCallback) => async () => {
		await axios
			.delete('/product/' + id)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const createProduct =
	(product: Product, callback: RequestCallback) => async () => {
		await axios
			.post('/product/', product)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const updateProduct =
	(product: Product, callback: RequestCallback) => async () => {
		await axios
			.put('/product/' + product.product_id, product)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const linkProvider =
	(
		product_id: ID,
		store_provider_id: ID,
		provider_product_id: ID,
		provider_url: string,
		callback: RequestCallback
	) =>
	async () => {
		await axios
			.post(`/provider/product/${product_id}`, {
				store_provider_id: store_provider_id,
				provider_key: provider_product_id,
				provider_url: provider_url,
			})
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const unlinkProvider =
	(product_id: string, callback: RequestCallback) => async () => {
		await axios
			.delete(`/provider/product/${product_id}`)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const getProductStats =
	(product_id: ID, query: string, callback: RequestCallback) => async () => {
		await axios
			.get(`/product/${product_id}/stats?${query}`)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const getProduct = (product_id: ID, callback: RequestCallback) => async () => {
	await axios.get(`/product/${product_id}`)
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};
