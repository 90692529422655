import React from 'react';
import PropTypes from 'prop-types';

const ModelViewerSimpleAnnotations = (props) => {
	const { data } = props;

	const generateSlides = () => {
		let annotations = [];

		data.map((annotation, index) => {
			annotations.push(
				<button
					className="Hotspot"
					id="simpleHotspot"
					slot={'hotspot-s' + index}
					data-position={annotation.position}
					data-normal={annotation.normal}
					data-visibility-attribute="visible"
				>
					<div className="HotspotAnnotation">{annotation.message}</div>
				</button>
			);
		});

		return annotations;
	};

	return <>{generateSlides()}</>;
};

ModelViewerSimpleAnnotations.propTypes = {
	data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

ModelViewerSimpleAnnotations.defaultProps = {};

export default ModelViewerSimpleAnnotations;
