import React from 'react';
import Chartist from 'react-chartist';
import ChartistTooltip from 'chartist-plugin-tooltips-updated';
import { Spinner } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { useState } from '@hookstate/core';
import { chartStore } from '../../store';
import { useTranslation } from 'react-i18next';
import { Backdrop } from '..';
import '../style.css';

export const LineChart = (props) => {
	const { labels, dataY1, dataY2 } = props;

	const { t, ready } = useTranslation('components');

	const globalChartData = useState(chartStore);

	const data = {
		labels: labels,
		datasets: [
			{
				label: t('charts.number_of_visits'),
				data: dataY1,
				fill: false,
				backgroundColor: '#009FE3',
				borderColor: '#009FE3',
				yAxisID: 'y1',
			},
			{
				label: t('charts.number_of_augmentations'),
				data: dataY2,
				fill: false,
				backgroundColor: '#599A17',
				borderColor: '#599A17',
				yAxisID: 'y1',
			},
		],
	};

	const options = {
		type: 'line',
		responsive: true,
		maintainAspectRatio: true,
		interaction: {
			mode: 'index',
			intersect: false,
		},
		stacked: false,
		plugins: {

		},
		animation: {
			onComplete: (e) => {
				globalChartData.img.set(e.chart.canvas.toDataURL('image/png'));
				globalChartData.height.set(e.chart.canvas.height);
				globalChartData.width.set(e.chart.canvas.width);
			},
		},
		scales: {
			y: {
				type: 'linear',
				display: false,
				position: 'left',
				min: 0,
			},
			y1: {
				type: 'linear',
				display: true,
				position: 'left',
				title: {
					display: true,
					text: t('common:amount'),
					color: '#009FE3',
					font: {
						family: 'Arial',
						size: 20,
						style: 'normal',
						lineHeight: 1.2,
					},
					padding: { top: 30, left: 0, right: 0, bottom: 0 },
				},
			},
			y2: {
				type: 'linear',
				display: false,
				position: 'right',
				title: {
					display: true,
					text: t('common:augmentations'),
					color: '#FF8A73',
					font: {
						family: 'Arial',
						size: 20,
						style: 'normal',
						lineHeight: 1.2,
					},
					padding: { top: 30, left: 0, right: 0, bottom: 0 },
				},
				// grid line settings
				grid: {
					drawOnChartArea: false, // only want the grid lines for one axis to show up
				},
			},
		},
	};

	return (
		<>
			<>
				{globalChartData.isLoaded.get() ? (
					<Line
						data={data}
						options={options}
						className="lineChart"
						height="100"
					/>
				) : (
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							margin: '150px 0',
						}}
					>
						<Spinner color="#009FE3" animation="border" />
					</div>
				)}
			</>
		</>
	);
};

LineChart.propTypes = {
	labels: PropTypes.array.isRequired,
	dataY1: PropTypes.array.isRequired,
	dataY2: PropTypes.array,
};

export const SalesValueChartphone = () => {
	const data = {
		labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
		series: [[1, 2, 2, 3, 3, 4, 3]],
	};

	const options = {
		low: 0,
		showArea: true,
		fullWidth: false,
		axisX: {
			position: 'end',
			showGrid: true,
		},
		axisY: {
			// On the y-axis start means left and end means right
			showGrid: false,
			showLabel: false,
			labelInterpolationFnc: (value) => `$${value / 1}k`,
		},
	};

	const plugins = [ChartistTooltip()];

	return (
		<Chartist
			data={data}
			options={{ ...options, plugins }}
			type="Line"
			className="ct-series-b ct-major-tenth"
		/>
	);
};

export const CircleChart = (props) => {
	const { series = [], donutWidth = 20 } = props;
	const sum = (a, b) => a + b;

	const options = {
		low: 0,
		high: 8,
		donutWidth,
		donut: true,
		donutSolid: true,
		fullWidth: false,
		showLabel: false,
		labelInterpolationFnc: (value) =>
			`${Math.round((value / series.reduce(sum)) * 100)}%`,
	};

	const plugins = [ChartistTooltip()];

	return (
		<Chartist
			data={{ series }}
			options={{ ...options, plugins }}
			type="Pie"
			className="ct-golden-section"
		/>
	);
};

export const BarChart = (props) => {
	const {
		labels = [],
		series = [],
		chartClassName = 'ct-golden-section',
	} = props;
	const data = { labels, series };

	const options = {
		low: 0,
		showArea: true,
		axisX: {
			position: 'end',
		},
		axisY: {
			showGrid: false,
			showLabel: false,
			offset: 0,
		},
	};

	const plugins = [ChartistTooltip()];

	return (
		<Chartist
			data={data}
			options={{ ...options, plugins }}
			type="Bar"
			className={chartClassName}
		/>
	);
};
