import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCog,
	faSignOutAlt,
	faUserShield,
} from '@fortawesome/free-solid-svg-icons';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import {
	Row,
	Col,
	Nav,
	Image,
	Navbar,
	Dropdown,
	Container,
	ListGroup,
	Badge,
} from 'react-bootstrap';
import { Routes } from '../../routes';
import NOTIFICATIONS_DATA from '../../data/notifications';
import Profile3 from '../../assets/img/team/profile-0.png';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AuthenticationRequests } from '../../requests';
import jwtDecode from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../LanguageSwitcher'

import '../style.css';

const TopNavbar = (props) => {
	const [notifications, setNotifications] = useState(NOTIFICATIONS_DATA);
	const areNotificationsRead = notifications.reduce(
		(acc, notif) => acc && notif.read,
		true
	);
	const history = useHistory();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const decoded = localStorage.getItem('token')
		? jwtDecode(localStorage.getItem('token'))
		: null;

	const markNotificationsAsRead = () => {
		setTimeout(() => {
			setNotifications(notifications.map((n) => ({ ...n, read: true })));
		}, 300);
	};

	const signOut = () => {
		dispatch(
			AuthenticationRequests.signOut((response) => {
				history.push(Routes.Signin.path);
			})
		);
	};

	const Notification = (props) => {
		const {
			link,
			sender,
			image,
			time,
			message,
			read = false,
			iconTitle,
			title,
		} = props;
		const readClassName = read ? '' : 'text-danger';

		return (
			<ListGroup.Item action href={link} className="border-bottom border-light">
				<Row className="align-items-center">
					<Col className="col-auto">
						<Image
							src={image}
							className="user-avatar lg-avatar rounded-circle"
						/>
					</Col>
					<Col className="ps-0 ms--2">
						<div className="d-flex justify-content-between align-items-center">
							<div>
								<h4 className="h6 mb-0 text-small">{sender}</h4>
							</div>
							<div className="text-end">
								<small className={readClassName}>{time}</small>
							</div>
						</div>
						<p className="font-small mt-1 mb-0">{message}</p>
					</Col>
				</Row>
			</ListGroup.Item>
		);
	};

	const { iconTitle, title, badgeText, onBadgeClick, onIconClick, image, copilotBtn } = props;

	return (
		<Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
			<Container fluid className="px-0">
				<div className="d-flex justify-content-between w-100">
					<div className="d-flex align-items-center">
						{iconTitle ? (
							<span className="icon icon-sm mb-2 px-2" onClick={onIconClick}>
								<FontAwesomeIcon icon={iconTitle} />
							</span>
						) : null}
						{image ? (
							<span className="icon icon-sm mb-2 px-2" onClick={onIconClick}>
								<Image src={image} width={20} height={20} className="sidebar-icon svg-icon" />
							</span>
						) : null}
						<h4 className="ml-10 text-primary">
							{title}{' '}
							<Badge
								onClick={() => {
									onBadgeClick();
								}}
								bg="danger"
								className="badge-md"
								style={{
									marginLeft: 5,
									fontSize: 12,
									position: 'absolute',
									marginTop: 6,
								}}
							>
								{badgeText}
							</Badge>
						</h4>
					</div>
					<Nav className="align-items-center">
						<div className='dv-nav-btn'>
							{copilotBtn}
						</div>
						<LanguageSwitcher/>
						<Dropdown as={Nav.Item}>
							<Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
								<div className="media d-flex align-items-center">
									<Image
										src={Profile3}
										className="user-avatar md-avatar rounded-circle"
									/>
									<div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
										{decoded && (
											<span className="mb-0 font-small fw-bold ">
												{decoded.name + ' ' + decoded.lastname}
											</span>
										)}
									</div>
								</div>
							</Dropdown.Toggle>
							<Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
								<Dropdown.Item
									className="fw-bold"
									onClick={() => {
										history.push({
											pathname: Routes.EditProfile.path,
										});
									}}
								>
									<FontAwesomeIcon icon={faUserCircle} className="me-2" />{' '}
									{t('my_profile')}
								</Dropdown.Item>
								{/* <Dropdown.Item className="fw-bold">
									<FontAwesomeIcon icon={faCog} className="me-2" />{' '}
									{t('settings')}
								</Dropdown.Item> */}
								<Dropdown.Item
									onClick={() => {
										history.push({
											pathname: Routes.Support.path,
										});
									}}
									className="fw-bold"
								>
									<FontAwesomeIcon icon={faUserShield} className="me-2" />{' '}
									{t('technical_support')}
								</Dropdown.Item>

								<Dropdown.Divider />

								<Dropdown.Item onClick={signOut} className="fw-bold">
									<FontAwesomeIcon
										icon={faSignOutAlt}
										className="text-danger me-2"
									/>
									{t('sign_out')}
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</Nav>
				</div>
			</Container>
		</Navbar>
	);
};

export default TopNavbar;
