import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useRef, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';

interface Props {
    filename: string
    headers: Array<object>
    onClick: () => Array<any>
    separator: string
    title: string
}

const ExportCsvGeneric = (props: Props) => {
    const {
        filename,
        headers,
        onClick,
        separator,
        title,
    } = props

    const { t } = useTranslation('components')

    const csvLinkRef = useRef<{ link: HTMLAnchorElement }>(null)

    const [data, setData] = useState<Array<any>>([])

    useEffect(() => {
        if (data.length > 0) {
            csvLinkRef.current?.link.click()
        }
    }, [data])

    return (
        <>
            <Button
                variant="secondary"
                onClick={async () => {                    
                    setData(await onClick())
                }}
                title={title}
            >
                <>
                    <FontAwesomeIcon
                        icon={faFileCsv as IconProp}
                        className="me-1 "
                        style={{ marginRight: 5 }}
                    />
                    {t(`export.csv`)}
                </>
            </Button>
            <CSVLink
                headers={headers}
                filename={`${filename}.csv`}
                data={data}
                ref={csvLinkRef}
                separator={separator}
            />
        </>
    )
}

ExportCsvGeneric.defaultProps = {
    filename: 'report',
    separator: ';',
    title: 'csv',
}

export default ExportCsvGeneric