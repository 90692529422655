import axios from 'axios';

export const linkStore =
	(code, hmac, host, shop, state, timestamp, store_id, callback) =>
	async (dispatch) => {
		await axios
			.post(process.env.REACT_APP_APPAR_PUBLIC_API + 'shopify/auth', {
				code: code,
				hmac: hmac,
				host: host,
				shop: shop,
				state: state,
				timestamp: timestamp,
				store_id: store_id,
			})
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const getProducts = (store_id, callback) => async (dispatch) => {
	await axios
		.get(
			process.env.REACT_APP_APPAR_PUBLIC_API +
				`shopify/store/${store_id}/products`
		)
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};
