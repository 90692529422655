import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { ReactElement } from 'react';

interface Props {
	headerContent: ReactElement,
	children: ReactElement,
	footerContent: ReactElement,
	open: boolean,
	onHide: () => void
}

const SimplifiedModal = (props: Props) => {
	const { headerContent, children, footerContent, open, onHide } = props;

	return (
		<Modal show={open} onHide={onHide} scrollable>
			{headerContent && (
				<Modal.Header closeButton>
					<Modal.Title>{headerContent}</Modal.Title>
				</Modal.Header>
			)}
			<Modal.Body>{children}</Modal.Body>
			{footerContent && <Modal.Footer>{footerContent}</Modal.Footer>}
		</Modal>
	);
};

SimplifiedModal.propTypes = {
	headerContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	open: PropTypes.bool,
	footerContent: PropTypes.element,
	onHide: PropTypes.func,
};

SimplifiedModal.defaultProps = {
	headerContent: null,
	open: false,
	footerContent: null,
};

export default SimplifiedModal;
