import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';

const Tooltip = (props) => {
	const { title, content, children, trigger, placement } = props;

	const popover = (
		<Popover id="popover-basic">
			{title !== '' && <Popover.Title as="h3">{title}</Popover.Title>}
			<Popover.Body>
				{content}
			</Popover.Body>
		</Popover>
	);

	return (
		<OverlayTrigger
			trigger={trigger}
			placement={placement}
			overlay={popover}
			rootClose
		>
			<Button variant="quaternary-outline mx-2" >
				{children}
			</Button>
		</OverlayTrigger>
	);
};

Tooltip.propTypes = {
	title: PropTypes.string,
	content: PropTypes.any,
	trigger: PropTypes.oneOf(['click', 'focus', 'hover']),
	placement: PropTypes.oneOf(['auto', 'left', 'right', 'top', 'bottom']),
};

Tooltip.defaultProps = {
	title: '',
	trigger: 'click',
	placement: 'auto',
};

export default Tooltip;
