import React, { useState, useEffect, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Routes } from '../routes';
import { lazyLoadScreen } from '../utils/components';

// components
import { Sidebar, Footer, Preloader } from '../components';
import Reports from './Admin/Reports';

// pages
const AdminAnalytics = lazyLoadScreen('Admin/Analytics');
const AdminApiAnalytics = lazyLoadScreen('Admin/ApiAnalytics');
const AdminIntegrity = lazyLoadScreen('Admin/Integrity');
const AdminPayments = lazyLoadScreen('Admin/Payments');
const AdminProducts = lazyLoadScreen('Admin/Products');
const AdminStore = lazyLoadScreen('Admin/Store');
const TeamWear = lazyLoadScreen('Admin/TeamWear');
const AdminUser = lazyLoadScreen('Admin/User');
const AdminProviders = lazyLoadScreen('Admin/Providers');
const AdminTopProducts = lazyLoadScreen('Admin/TopProducts');
const Analytics = lazyLoadScreen('Analytics');
const BuyPlan = lazyLoadScreen('BuyPlan');
const Category = lazyLoadScreen('Category');
const Checkout = lazyLoadScreen('Checkout');
const Collection = lazyLoadScreen('Collection');
const CreateCollection = lazyLoadScreen('Collection/CreateCollection');
const CreateProduct = lazyLoadScreen('Product/CreateProduct');
const EditProfile = lazyLoadScreen('EditProfile');
const FAQ = lazyLoadScreen('Public/PublicFAQ');
const ForgotPassword = lazyLoadScreen('ForgotPassword');
const FrequentQuestions = lazyLoadScreen('FrequentQuestions');
const GlobalMessage = lazyLoadScreen('Admin/GlobalMessage');
const Link = lazyLoadScreen('Link');
const Overview = lazyLoadScreen('Overview');
const Payment = lazyLoadScreen('Payment');
const PaypalLink = lazyLoadScreen('Payment/PayPal/Link');
const Pricing = lazyLoadScreen('Pricing');
const PricingPreview = lazyLoadScreen('Public/PricingPreview');
const PrivacyPolicy = lazyLoadScreen('PrivacyPolicy');
const Product = lazyLoadScreen('Product');
const PublicSupport = lazyLoadScreen('Public/PublicSupport');
const ResetPassword = lazyLoadScreen('ResetPassword');
const Shopify = lazyLoadScreen('Integrations/Shopify');
const Signin = lazyLoadScreen('Signin');
const Signup = lazyLoadScreen('Signup');
const SocialLogin = lazyLoadScreen('SocialLogin');
const Store = lazyLoadScreen('Store');
const StoreLink = lazyLoadScreen('Store/Link');
const Support = lazyLoadScreen('Support');
const Terms = lazyLoadScreen('Public/Terms');
const ThreeDEditor = lazyLoadScreen('3DEditor');
const ThreeDViewer = lazyLoadScreen('3DViewer');
const TiendaNube = lazyLoadScreen('Integrations/Tiendanube');
const Multivende = lazyLoadScreen('Integrations/Multivende');
const Tutorial = lazyLoadScreen('Tutorial');
const User = lazyLoadScreen('User');
const Verify = lazyLoadScreen('Verify');
const Vtex = lazyLoadScreen('Integrations/Vtex');

const RouteWithLoader = ({ component: Component, ...rest }) => {
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => setLoaded(true), 1000);
		return () => clearTimeout(timer);
	}, []);

	return (
		<Route
			{...rest}
			render={(props) => (
				<>
					{' '}
					<Preloader show={loaded ? false : true} /> <Component {...props} />{' '}
				</>
			)}
		/>
	);
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => setLoaded(true), 1000);
		return () => clearTimeout(timer);
	}, []);

	const localStorageIsSettingsVisible = () => {
		return localStorage.getItem('settingsVisible') === 'false' ? false : true;
	};

	const [showSettings, setShowSettings] = useState(
		localStorageIsSettingsVisible
	);

	const toggleSettings = () => {
		setShowSettings(!showSettings);
		localStorage.setItem('settingsVisible', !showSettings);
	};

	return (
		<Route
			{...rest}
			render={(props) => (
				<>
					<Preloader show={loaded ? false : true} />
					<Sidebar />

					<main className="content">
						<Component {...props} />
						<Footer
							toggleSettings={toggleSettings}
							showSettings={showSettings}
						/>
					</main>
				</>
			)}
		/>
	);
};

const HomePage = () => (
	<>
		<Suspense fallback={<Preloader show={true} />}>
			<Switch>
				{/* Loader */}
				<RouteWithLoader exact path={Routes.FAQ.path} component={FAQ} />
				<RouteWithLoader
					exact
					path={Routes.ForgotPassword.path}
					component={ForgotPassword}
				/>
				<RouteWithLoader
					exact
					path={Routes.PricingPreview.path}
					component={PricingPreview}
				/>
				<RouteWithLoader
					exact
					path={Routes.PrivacyPolicy.path}
					component={PrivacyPolicy}
				/>
				<RouteWithLoader
					exact
					path={Routes.PublicSupport.path}
					component={PublicSupport}
				/>
				<RouteWithLoader
					exact
					path={Routes.ResetPassword.path}
					component={ResetPassword}
				/>
				<RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
				<RouteWithLoader exact path={Routes.Signup.path} component={Signup} />
				<RouteWithLoader exact path={Routes.Terms.path} component={Terms} />
				<RouteWithLoader exact path={Routes.Verify.path} component={Verify} />
				<RouteWithLoader
					exact
					path={Routes.StoreLink.path}
					component={StoreLink}
				/>
				<RouteWithLoader
					exact
					path={Routes.SocialLogin.path}
					component={SocialLogin}
				/>

				{/* Sidebar */}
				<RouteWithSidebar
					exact
					path={Routes.AdminAnalytics.path}
					component={AdminAnalytics}
				/>
				<RouteWithSidebar
					exact
					path={Routes.AdminIntegrity.path}
					component={AdminIntegrity}
				/>
				<RouteWithSidebar
					exact
					path={Routes.GlobalMessage.path}
					component={GlobalMessage}
				/>
				<RouteWithSidebar
					exact
					path={Routes.AdminPayments.path}
					component={AdminPayments}
				/>
				<RouteWithSidebar
					exact
					path={Routes.AdminTopProducts.path}
					component={AdminTopProducts}
				/>
				<RouteWithSidebar
					exact
					path={Routes.AdminApiAnalytics.path}
					component={AdminApiAnalytics}
				/>
				<RouteWithSidebar
					exact
					path={Routes.AdminProducts.path}
					component={AdminProducts}
				/>
				<RouteWithSidebar
					exact
					path={Routes.AdminUser.path}
					component={AdminUser}
				/>
				<RouteWithSidebar
					exact
					path={Routes.Analytics.path}
					component={Analytics}
				/>
				<RouteWithSidebar
					exact
					path={Routes.BuyPlan.path}
					component={BuyPlan}
				/>
				<RouteWithSidebar
					exact
					path={Routes.Category.path}
					component={Category}
				/>
				<RouteWithSidebar
					exact
					path={Routes.Collection.path}
					component={Collection}
				/>
				<RouteWithSidebar
					exact
					path={Routes.CreateCollection.path}
					component={CreateCollection}
				/>
				<RouteWithSidebar
					exact
					path={Routes.CreateProduct.path}
					component={CreateProduct}
				/>
				<RouteWithSidebar
					exact
					path={Routes.EditProfile.path}
					component={EditProfile}
				/>
				<RouteWithSidebar
					exact
					path={Routes.FrequentQuestions.path}
					component={FrequentQuestions}
				/>
				<RouteWithSidebar
					exact
					path={Routes.Overview.path}
					component={Overview}
				/>
				<RouteWithSidebar
					exact
					path={Routes.Payment.path}
					component={Payment}
				/>
				<RouteWithSidebar
					exact
					path={Routes.Pricing.path}
					component={Pricing}
				/>
				<RouteWithSidebar
					exact
					path={Routes.Products.path}
					component={Product}
				/>
				<RouteWithSidebar exact path={Routes.Store.path} component={Store} />
				<RouteWithSidebar
					exact
					path={Routes.Support.path}
					component={Support}
				/>
				<RouteWithSidebar
					exact
					path={Routes.Tutorial.path}
					component={Tutorial}
				/>
				<RouteWithSidebar exact path={Routes.User.path} component={User} />
				<RouteWithSidebar
					exact
					path={Routes.TiendaNube.path}
					component={TiendaNube}
				/>
				<RouteWithSidebar
					exact
					path={Routes.Multivende.path}
					component={Multivende}
				/>
				<RouteWithSidebar exact path={Routes.Vtex.path} component={Vtex} />
				<RouteWithSidebar
					exact
					path={Routes.Shopify.path}
					component={Shopify}
				/>
				<RouteWithSidebar
					exact
					path={Routes.PaypalLink.path}
					component={PaypalLink}
				/>
				<RouteWithSidebar
					exact
					path={Routes.Checkout.path}
					component={Checkout}
				/>
				<RouteWithSidebar exact path={Routes.Link.path} component={Link} />
				<RouteWithSidebar
					exact
					path={Routes.AdminStore.path}
					component={AdminStore}
				/>
				<RouteWithSidebar
					exact
					path={Routes.AdminProviders.path}
					component={AdminProviders}
				/>
				<RouteWithSidebar
					exact
					path={Routes.ThreeDViewer.path}
					component={ThreeDViewer}
				/>
				<RouteWithSidebar
					exact
					path={Routes.ThreeDEditor.path}
					component={ThreeDEditor}
				/>
				<RouteWithSidebar
					exact
					path={Routes.Reports.path}
					component={Reports}
				/>
				<RouteWithSidebar
					exact
					path={Routes.AdminTeamWear.path}
					component={TeamWear}
				/>
				<Redirect to={Routes.Signin.path} />
			</Switch>
		</Suspense>
	</>
);

export default HomePage;
