import moment from 'moment';

export const getWeekDays = () => {
	return [
		{
			name: 'monday',
			short: 'day.mon.short',
		},
		{
			name: 'tuesday',
			short: 'day.tue.short',
		},
		{
			name: 'wednesday',
			short: 'day.wed.short',
		},
		{
			name: 'thursday',
			short: 'day.thu.short',
		},
		{
			name: 'friday',
			short: 'day.fri.short',
		},
		{
			name: 'saturday',
			short: 'day.sat.short',
		},
		{
			name: 'sunday',
			short: 'day.sun.short',
		},
	];
};

export const getMonths = () => {
	return [
		{
			name: 'january',
			short: 'month.jan.short',
			full: 'month.jan.long',
		},
		{
			name: 'february',
			short: 'month.feb.short',
			full: 'month.feb.long',
		},
		{
			name: 'march',
			short: 'month.mar.short',
			full: 'month.mar.long',
		},
		{
			name: 'april',
			short: 'month.apr.short',
			full: 'month.apr.long',
		},
		{
			name: 'may',
			short: 'month.may.short',
			full: 'month.may.long',
		},
		{
			name: 'june',
			short: 'month.jun.short',
			full: 'month.jun.long',
		},
		{
			name: 'july',
			short: 'month.jul.short',
			full: 'month.jul.long',
		},
		{
			name: 'august',
			short: 'month.aug.short',
			full: 'month.aug.long',
		},
		{
			name: 'september',
			short: 'month.sep.short',
			full: 'month.sep.long',
		},
		{
			name: 'october',
			short: 'month.oct.short',
			full: 'month.oct.long',
		},
		{
			name: 'november',
			short: 'month.nov.short',
			full: 'month.nov.long',
		},
		{
			name: 'december',
			short: 'month.dec.short',
			full: 'month.dec.long',
		},
	];
};

export const getCurrentDate = () => {
	return moment().format('dddd D, MMMM yyyy');
};

export const getDaysLeftUntilNow = (date) => {
	return moment(date).diff(moment(), 'days')
}

export const getDaysBetweenDates = (startDate, endDate) => {
	let now = moment(startDate).clone() 
	let dates = []

	while (now.isSameOrBefore(endDate)) {
	 	dates.push(now.format('DD MMM YY'))
		now.add(1, 'days')
	}

	return dates
}