import { useTranslation } from "react-i18next";
import {
    Nav,
    Image,
    Dropdown,
} from 'react-bootstrap';
import '../style.css';

const LanguageSwitcher = () => {

    const { t, i18n } = useTranslation();

    const supportedLanguages = [
        {
            "name": "Español",
            "code": "spanish",
            "short": "es",
            "icon": "/images/flags/espana.png"
        },
        {
            "name": "English",
            "code": "english",
            "short": "en",
            "icon": "/images/flags/reino-unido.png"
        },
        {
            "name": "Português",
            "code": "português",
            "short": "pt",
            "icon": "/images/flags/brasil.png"
        }
    ]

    return (
        <Dropdown as={Nav.Item}>
            <Dropdown.Toggle as={Nav.Link}>
                <div className="media d-flex align-items-center" style={{ marginBottom: "5px", color: "black" }}>
                    <Image src={supportedLanguages.filter(lang => lang.short == i18n.language)[0].icon} className="md-avatar rounded-circle" style={{ maxWidth: "25px", marginRight: "5px" }} />
                    {supportedLanguages.filter(lang => lang.short == i18n.language)[0].name}
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {supportedLanguages.map((lang) => (
                    <Dropdown.Item key={lang.short} onClick={() => {
                        i18n.changeLanguage(lang.short);
                    }}>
                        <Image src={lang.icon} style={{ maxWidth: "30px", marginRight: "5px" }} />
                        {t(lang.name)}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown >
    )
}

export default LanguageSwitcher