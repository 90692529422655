import axios from 'axios';
import { Annotation, RequestCallback } from 'typings/custom';

export const createAnnotations =
	(model_id: ID, annotations: Array<Annotation>, callback: RequestCallback) => async () => {
		await axios
			.post(`/model/${model_id}/annotation`, annotations)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const updateAnnotations =
	(model_id: ID, annotations: Array<Annotation>, callback: RequestCallback) => async () => {
		await axios
			.put(`/model/${model_id}/annotation`, annotations)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};
