import moment from 'moment';
import React from 'react';

export const lazyLoadScreen = (element: any) => {
	return React.lazy(() => {
		return new Promise((resolve) => {
			setTimeout(() => resolve(import(`../screens/${element}`)), 500);
		});
	});
};

export const processDataForCsv = (
	visits: any[],
	augmentations: any[],
	dates: any,
	onResult: (result: any) => void
) => {
	let array = [];

	let days = null;
	let date: any = null;

	if (dates) {
		days = moment(dates!.endDate!).diff(
			moment(dates!.startDate!),
			'days',
			true
		);
		date = moment(dates!.startDate!).format('YYYY-MM-DD');
	}

	if (days && date) {
		for (var i = 0; i <= days; i++) {
			var element = {
				date: date,
				visits: 0,
				augmentations: 0,
			};
			if (visits !== null) {
				visits.filter((visit) => {
					if (
						visit.day == moment(date).date() &&
						visit.month == moment(date).month() + 1 &&
						visit.year == moment(date).year()
					) {
						element = { ...element, visits: visit.count };
					}
				});
			}
			if (augmentations !== null) {
				augmentations.filter((augmentation) => {
					if (
						augmentation.day == moment(date).date() &&
						augmentation.month == moment(date).month() + 1 &&
						augmentation.year == moment(date).year()
					) {
						element = { ...element, augmentations: augmentation.count };
					}
				});
			}
			array.push(element);

			date = moment(date).add(1, 'day').format('YYYY-MM-DD');
		}
	}

	onResult(array);
};
