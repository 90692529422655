import axios from 'axios';
import { RequestCallback, User } from 'typings/custom';

export const createStore =
	(store: Store, callback: RequestCallback) => async () => {
		await axios
			.post('/store/', store)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const getUserStores =
	(user_id: ID, callback: RequestCallback) => async () => {
		await axios
			.get(`/store/user/${user_id}`)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const updateStore =
	(store: Store, callback: RequestCallback) => async () => {
		await axios
			.put('/store/' + store.store_id, store)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const deleteStore = (id: ID, callback: RequestCallback) => async () => {
	await axios
		.delete(`/store/${id}`)
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};

export const getStoreProducts =
	(
		id: ID,
		page: number,
		per_page: number,
		query: string,
		sort: string,
		callback: RequestCallback
	) =>
	async () => {
		await axios
			.get(
				`/store/${id}/products?page=${page}&per_page=${per_page}&q=${query}&sort=${sort}`
			)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const getStoreVisits =
	(id: ID, query: string, callback: RequestCallback) => async () => {
		await axios
			.get('/store/' + id + '/stats/visits?' + query)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const getStoreAugmentations =
	(id: ID, query: string, callback: RequestCallback) => async () => {
		await axios
			.get('/store/' + id + '/stats/augmentations?' + query)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const createManager =
	(manager: User, callback: RequestCallback) => async () => {
		await axios
			.post('/store/manager', manager)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const deleteManager =
	(manager_id: ID, callback: RequestCallback) => async () => {
		await axios
			.delete('/store/manager/' + manager_id)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const updateManager =
	(manager: User, callback: RequestCallback) => async () => {
		await axios
			.put(`/store/manager/${manager.user_id}`, manager)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const getStoreCollections =
	(
		id: ID,
		page: number,
		per_page: number,
		query: string,
		callback: RequestCallback
	) =>
	async () => {
		await axios
			.get(
				`/store/${id}/collections?page=${page}&per_page=${per_page}&q=${query}`
			)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const unlinkStore =
	(store_id: ID, callback: RequestCallback) => async () => {
		await axios
			.delete(`/store/${store_id}/provider`)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const getQR = (store_id: ID, callback: RequestCallback) => async () => {
	await axios
		.get(`/store/${store_id}/qr`)
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};

export const updateQR =
	(store_id: ID, color: string, callback: RequestCallback) =>
	async () => {
		await axios
			.put(`/store/${store_id}/qr`, { color })
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const uploadQRLogo =
	(store_id: ID, file: any, callback: RequestCallback) => async () => {
		var data = new FormData();
		data.append('file', file);

		await axios
			.post(`/store/${store_id}/qr/logo`, data, {
				headers: { 'content-type': 'multipart/form-data' },
			})
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const deleteQRLogo =
	(store_id: ID, callback: RequestCallback) => async () => {
		await axios
			.delete(`/store/${store_id}/qr/logo`)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const getAugmentationButton =
	(store_id: ID, callback: RequestCallback) => async () => {
		await axios
			.get(`/store/${store_id}/augmentationbtn`)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const updateAugmentationButton =
	(store_id: ID, augbtn: AugmentationButton, callback: RequestCallback) =>
	async () => {
		await axios
			.put(`/store/${store_id}/augmentationbtn`, augbtn)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const getStore =
	(store_id: ID, callback: RequestCallback) => async () => {
		await axios
			.get(`/store/${store_id}`)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const getStoreProductsSummary = async (storeId: ID) => {
	var data;
	var error;

	await axios
		.get(`/store/${storeId}/products/summary`)
		.then((res) => {
			if (res) {
				data = res.data;
			}
		})
		.catch((e) => {
			error = e;
		});

	return { data, error };
};
