import axios from 'axios';

export const linkStore = (code, store_id, callback) => async (dispatch) => {
	await axios
		.post(process.env.REACT_APP_APPAR_PUBLIC_API + 'multivende/auth', {
			code: code,
			store_id: store_id,
		})
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};

export const getProducts = (store_id, page = 1, callback) => async (dispatch) => {
		await axios
			.get(process.env.REACT_APP_APPAR_PUBLIC_API + `multivende/store/${store_id}/products?page=${page}`)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const createAndLink = (code, user, callback) => async (dispatch) => {
	await axios
		.post(`/user/multivende`, { code: code, user: user })
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};

export const productWebhook = (product_id, callback) => async (dispatch) => {
	await axios
		.post(process.env.REACT_APP_APPAR_PUBLIC_API + "/multivende/webhook", { ProductId: product_id, resource: "prodcuts" })
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};
