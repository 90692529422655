import { ReactNode } from "react";
import { Spinner } from "react-bootstrap"
import { useTranslation } from "react-i18next";

const Loading = () => {

    const { t } = useTranslation();

    return (
        <div style={{
            margin: 'auto',
            width: '50%',
            textAlign: 'center',
            marginTop: '100px',
            marginBottom: '100px'
        }}	>
            <Spinner
                animation="border"
                role="status"
                variant='primary'
            />
            <p>{t('loading') as ReactNode}...</p>
        </div>
    )
}

export default Loading