import axios from 'axios';
import { RequestCallback } from 'typings/custom';

export const linkAccount = (code: string, callback: RequestCallback) => async () => {
	await axios
		.post(process.env.REACT_APP_APPAR_PUBLIC_API + 'paypal/link', {
			code: code,
		})
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};

export const checkAccountStatus = (callback: RequestCallback) => async () => {
	await axios
		.get(`${process.env.REACT_APP_API_URL}payment/paypal/status`)
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};
