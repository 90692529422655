import { Image } from 'react-bootstrap';
import './style.css';

export const Shopify = () => (
	<Image
		className="integrationButton"
		src="/images/integrations/Shopify-Logo.png"
		onClick={() =>
			(window.location.href = `https://apparplus.myshopify.com/admin/oauth
                /authorize?client_id=daa60bfb0c0c4f93209360d9c84d762a&scope=read_products&redirect_uri=
                ${process.env.REACT_APP_DASHBOARD_URL}/store/link/shopify&state=1633373697`)
		}
	/>
);

export const TiendaNube = () => (
	<Image
		className="integrationButton"
		src="/images/integrations/tiendanube-640w.png"
		onClick={() =>
			(window.location.href = 'https://www.tiendanube.com/apps/3605/authorize')
		}
	/>
);

export const Vtex = () => (
	<Image
		className="integrationButton"
		src="/images/integrations/VTEX_Logo.png"
		onClick={() =>
			(window.location.href = 'https://www.tiendanube.com/apps/3605/authorize')
		}
	/>
);

export const Paypal = () => (
	<Image className="integrationButton" src="/images/integrations/paypal.svg" />
);

export const Multivende = () => (
	<div style={{display:"inline-flex", height:"auto", padding:"10px"}} className="integrationButton"
		onClick={() => (window.location.href = 'https://app.multivende.com/apps/authorize?response_type=code&client_id=615185272445')}>
		<Image style={{height: "60px"}} src="/images/integrations/multivende_logo.png"/>
		<h4 style={{alignSelf:"center", paddingLeft:"10px", paddingTop:"10px"}}>Multivende</h4>
	</div>
);