import axios from 'axios';
import { Category, RequestCallback } from 'typings/custom';

export const createCategory =
	(category: Category, callback: RequestCallback) => async () => {
		await axios
			.post('/category', category)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const getCategories = (callback: RequestCallback) => async () => {
	await axios
		.get('/category')
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};

export const updateCategory =
	(category: Category, callback: RequestCallback) => async () => {
		await axios
			.put(`/category/${category.category_id}`, {
				name: category.name,
			})
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const deleteCategory =
	(category_id: ID, callback: RequestCallback) => async () => {
		await axios
			.delete(`/category/${category_id}`)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const createSubcategory =
	(category: Category, subcatergory: Category, callback: RequestCallback) =>
	async () => {
		await axios
			.post(`/category/${category.category_id}/sub`, {
				name: subcatergory.name,
			})
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const updateSubcategory =
	(subcategory: Category, callback: RequestCallback) => async () => {
		await axios
			.put(
				`/category/${subcategory.category_id}/sub/${subcategory.sub_category_id}`,
				{ name: subcategory.name }
			)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const deleteSubcategory =
	(subcategory: Category, callback: RequestCallback) => async () => {
		await axios
			.delete(
				`/category/${subcategory.category_id}/sub/${subcategory.sub_category_id}`
			)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};
