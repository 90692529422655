import moment from 'moment';

export const objectIsEmpty = (obj) => {
	return Object.keys(obj).length === 0;
};

export const checkPassword = (passwordA, passwordB) => {
	passwordA = passwordA ? passwordA : '';
	passwordB = passwordB ? passwordB : '';
	let response = {
		isValid: passwordA === passwordB,
		errMsg: 'password_do_not_match',
	};

	if (response.isValid)
		response = { isValid: passwordA.length >= 8, errMsg: 'password_too_short' };

	return response;
};

export const downloadQR = () => {
	const canvas = document.getElementById('qrcode');
	const a = document.createElement('a');
	a.download = 'qrcode';
	a.href = canvas.toDataURL('image/png;base64');
	a.click();
};

export const getAdvancedCode = (id, type) => {
	var url = '';
	switch (type) {
		case 0:
			url = process.env.REACT_APP_APPAR_MODELVIEWER + 'collection/' + id;
			break;
		case 1:
			url = process.env.REACT_APP_APPAR_MODELVIEWER + 'room-builder/' + id;
			break;
		default:
			url = process.env.REACT_APP_APPAR_MODELVIEWER + id;
			break;
	}

	return (
		`<iframe src="${url}" style="border:0px #ffffff none;" name="appar"\n` +
		`scrolling="no" frameborder="0" allow="camera" marginheight="0px" marginwidth="0px" height="500px"\n` +
		`width="600px" allow="xr-spatial-tracking; fullscreen"></iframe>`
	);
};

export const getConnectorCode = (id, type) => {
	return (
		`<div id="appar">\n` +
		`\t<input hidden value="${id}" style="display: none" id="${type}">\n` +
		`\t<script src="https://data.appar.io/scripts/appar-script-connector.js"></script>\n` +
		`</div>`
	);
};

export const getPaymentState = (data) => {
	let state = 'active';
	const timeRemain = (moment(data.expires_at) - moment()) / 1000 / 60 / 60 / 24;

	if (timeRemain <= -1) {
		state = 'expired';
	} else if (!data.subscription_id && data.token) {
		state = 'active_to_be_canceled';
	}

	return state;
};

export const getParameter = (location) => {
	let response = {
		success: true,
		data: null,
	};

	let urlParams = location.search;
	if (urlParams.includes('?')) {
		urlParams = location.search.split('?');
	} else {
		urlParams = null;
	}

	if (urlParams) {
		const rawParams = urlParams[1].split('&');

		let processedParams = {};

		rawParams.map((element) => {
			const splited = element.split('=');
			processedParams[splited[0]] = splited[1];
		});

		response = {
			success: true,
			data: processedParams,
		};
	} else {
		response = {
			success: false,
			data: 'no_parameter',
		};
	}

	return response;
};

export const dataURLtoFile = (dataURL, format) => {
	var arr = dataURL.split(','),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = window.atob(arr[1]),
		n = bstr.length,
		u8arr = new Uint8Array(n);
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new File([u8arr], format, { type: mime });
};

export const getUserType = (userTypeId) => {
	switch (userTypeId) {
		default:
			return 'incorrect_id'
		case 1:
			return 'admin'
		case 2:
			return 'owner'
		case 3:
			return 'manager'
	}
}

export const validateUrlHttps = (url) => {
	const cleanText = url.toLowerCase().trim()
	let result = ""
	
	if (url === "") {
		return ""
	}

	if (!cleanText.includes("http")) {
		result = `https://${url}`
	} else if (cleanText.includes("http") && !cleanText.includes("https")) {
		result = cleanText.replace("http", "https")
	} else {
		result = cleanText
	}
	return result
}