import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faEye,
	faEllipsisH,
	faEdit,
	faTrash,
	faCube,
	faCode,
	faQrcode,
	faChartLine,
} from '@fortawesome/free-solid-svg-icons';
import {
	Row,
	Col,
	Image,
	Dropdown,
	Button,
	Card,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import image from '../../assets/img/no-model.png';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../routes';
import PropTypes from 'prop-types';
import { Tooltip } from '..';
import './style.css';

const GridItem = (props) => {
	const { t } = useTranslation(['product']);
	const history = useHistory();

	const {
		customOptions,
		element,
		onDelete,
		onFooterClick,
		onGridClick,
		selectedStore,
		showCode,
		showQr,
		showPreview,
		showStats
	} = props;

	return (
		<Card className="mb-3">
			<Card.Body>
				<div>
					<div className="rounded rounded-0 card-header">
						<Row>
							<Col
								xs={10}
								md={10}
								onClick={() => onGridClick(element)}
								style={{ cursor: 'pointer' }}
							>
								<p>{element.name}</p>
							</Col>
							<Col xs={2} md={2}>
								<Dropdown className="m-0 p-0">
									<Dropdown.Toggle
										as={Button}
										variant="primary"
										size="sm"
										className="me-2"
										style={{
											backgroundColor: 'transparent',
											border: 'none',
											color: '#332e47',
										}}
									>
										<FontAwesomeIcon icon={faEllipsisH} className="me-2" />
									</Dropdown.Toggle>
									<Dropdown.Menu
										id="AA"
										className="dashboard-dropdown dropdown-menu-left mt-2 transform"
									>
										{!customOptions && (
											<>
												{element.status !== 'no_model' && (
													<>
														<Dropdown.Item
															className="fw-bold"
															onClick={() => {
																showCode(element);
															}}
														>
															<FontAwesomeIcon
																icon={faCode}
																className="me-2"
															/>{' '}
															{t('product:product_insert_in_store')}
														</Dropdown.Item>

														<Dropdown.Item
															className="fw-bold"
															onClick={() => {
																showQr(element);
															}}
														>
															<FontAwesomeIcon
																icon={faQrcode}
																className="me-2"
															/>{' '}
															{t('product:product_qr')}
														</Dropdown.Item>
														<Dropdown.Item
															className="fw-bold"
															onClick={() => {
																showPreview(element);
															}}
														>
															<FontAwesomeIcon
																icon={faEye}
																className="me-2"
															/>{' '}
															{t('common:preview')}
														</Dropdown.Item>
														<Dropdown.Item
															className="fw-bold"
															onClick={() => {
																showStats(element);
															}}
														>
															<FontAwesomeIcon
																icon={faChartLine}
																className="me-2"
															/>
															{t('common:statistics')}
														</Dropdown.Item>


													</>
												)}
												<Dropdown.Divider />
												<Dropdown.Item
													className="fw-bold"
													onClick={() => {
														history.push({
															pathname: Routes.CreateProduct.path,
															state: {
																product: element,
																store: selectedStore,
																action: 'edit',
															},
														});
													}}
												>
													<FontAwesomeIcon icon={faEdit} className="me-2" />{' '}
													{t('product:product_edit')}
												</Dropdown.Item>

												<Dropdown.Item
													className="fw-bold"
													onClick={() => {
														onDelete(element);
													}}
												>
													<FontAwesomeIcon
														icon={faTrash}
														className="text-danger me-2"
													/>{' '}
													{t('product:product_delete')}
												</Dropdown.Item>
											</>
										)}
										{customOptions && (
											<>
												{customOptions.map((option) => {
													return (
														<Dropdown.Item
															className="fw-bold"
															onClick={() => option.onOptionClick(element)}
														>
															<FontAwesomeIcon
																icon={option.optionIcon}
																className="text-danger me-2"
															/>{' '}
															{option.optionName}
														</Dropdown.Item>
													);
												})}
											</>
										)}
									</Dropdown.Menu>
								</Dropdown>
							</Col>
						</Row>
						<Row
							onClick={() => {
								(element.poster_url ||
									element.android_model_url) &&
									onGridClick(element);
							}}
						>
							<div>
								{element.poster_url ||
									!element.android_model_url ? (
									<Image
										title={t('poster')}
										src={
											element.poster_url
												? element.poster_url
												: image
										}
										className="card-img-top rounded mb-3"
										style={{
											maxHeight: '134px',
											minHeight: '134px',
											width: 'auto',
											margin: 'auto',
											display: 'block',
											cursor: 'pointer',
										}}
									/>
								) : (
									<model-viewer
										src={element.android_model_url}
										ios-src={element.ios_model_url}
										shadow-intensity="1"
										alt="AppAR model"
										style={{ width: 'auto' }}
										autoplay
										camera-orbit="45deg 55deg"
									/>
								)}

								{/* {element.android_model_url &&
                                       
                                    }
                                    {!element.android_model_url &&
                                        <Image title={t("no_model")} src={image} className="card-img-top rounded mb-3" style={{ maxHeight: '134px', width: 'auto', margin: 'auto', display: 'block' }} />
                                    } */}
							</div>
						</Row>
					</div>
				</div>
			</Card.Body>
			<Row onClick={() => onFooterClick(element)} style={{ cursor: 'pointer' }}>
				<div
					className="d-flex flex-wrap flex-lg-nowrap align-items-center post-meta"
					style={{ marginLeft: '6%' }}
				>
					<Tooltip content={t('common:visits')} trigger="hover" placement="top">
						<p>
							{' '}
							<FontAwesomeIcon
								icon={faEye}
								style={{ fontSize: 18, color: '#CA0B00', marginLeft: 10 }}
							/>{' '}
							{element.visits}
						</p>
					</Tooltip>
					<Tooltip
						content={t('common:augmentations')}
						trigger="hover"
						placement="top"
					>
						<p>
							{' '}
							<FontAwesomeIcon
								icon={faCube}
								style={{ fontSize: 18, color: '#CA0B00', marginLeft: 15 }}
							/>{' '}
							{element.augmentations}
						</p>
					</Tooltip>
				</div>
			</Row>
		</Card>
	);
};

GridItem.propTypes = {
	element: PropTypes.object.isRequired,
	onGridClick: PropTypes.func,
	onFooterClick: PropTypes.func,
	onDelete: PropTypes.func,
	showCode: PropTypes.func,
	showQr: PropTypes.func,
	selectedStore: PropTypes.object.isRequired,
	customOptions: PropTypes.arrayOf(
		PropTypes.shape({
			optionName: PropTypes.string,
			optionIcon: PropTypes.element,
			onOptionClick: PropTypes.func,
		})
	),
};

GridItem.defaultProps = {
	customOptions: null,
};

export default GridItem;
