import { Col, Row } from 'react-bootstrap';
import { GridItem, GridPagination, Loading } from '..';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const GridView = (props) => {
	const {
		customItemOptions,
		data,
		loading,
		onDelete,
		onFooterClick,
		onGridClick,
		onPageChange,
		selectedStore,
		showCode,
		showQr,
		showPreview,
		showStats,
		totalPages,
	} = props;

	const [currentPage, setCurrentPage] = useState(1);

	useEffect(() => {
		if (currentPage > 0 && currentPage <= totalPages)
			onPageChange(currentPage);
	}, [currentPage]);

	return (
		<div>
			{loading ?
				<Loading />
				:
				<Row>
					{data.map((element, index) => (
						<Col key={index} xs={12} md={6} lg={3} style={{ zIndex: 0 }}>
							<GridItem
								onGridClick={onGridClick}
								element={element}
								selectedStore={selectedStore}
								onDelete={onDelete}
								showCode={showCode}
								showQr={showQr}
								showPreview={showPreview}
								showStats={showStats}
								customOptions={customItemOptions}
								onFooterClick={onFooterClick}
							/>
						</Col>
					))}
				</Row>
			}

			<GridPagination
				currentPage={currentPage}
				lastPage={totalPages}
				onPageChange={(number) => setCurrentPage(number)}
			/>
		</div>
	);
};

GridView.propTypes = {
	customItemOptions: PropTypes.arrayOf(
		PropTypes.shape({
			optionName: PropTypes.string,
			optionIcon: PropTypes.element,
			onOptionClick: PropTypes.func,
		})
	),
	data: PropTypes.arrayOf(PropTypes.object).isRequired,
	loading: PropTypes.bool,
	onDelete: PropTypes.func,
	onFooterClick: PropTypes.func,
	onGridClick: PropTypes.func,
	selectedStore: PropTypes.object,
	showCode: PropTypes.func,
	showQr: PropTypes.func,
	showPreview: PropTypes.func,
	showStats: PropTypes.func,
	onPageChange: PropTypes.func,
	totalPages: PropTypes.number,
};

GridView.defaultProps = {
	onFooterClick: () => { },
	loading: false
};

export default GridView;
