import React from 'react';
import { jsPDF } from 'jspdf';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useState } from '@hookstate/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { chartStore } from '../../store';

interface Props {
	filename: string
}

const ExportPdf = (props: Props) => {
	const { t } = useTranslation('components');

	const { filename } = props;

	const globalChartData = useState(chartStore);

	const generatePdf = () => {
		if (globalChartData.isLoaded.get()) {
			const pdf = new jsPDF({
				orientation: 'landscape',
				unit: 'px',
				format: 'letter',
			});
			pdf.addImage(
				globalChartData.get().img,
				'png',
				25,
				50,
				globalChartData.get().width / 3,
				globalChartData.get().height / 3
			);
			pdf.save(`${filename}.pdf`);
		} else {
			console.warn('Graph data not loaded.');
		}
	};

	return (
		<Button onClick={generatePdf} variant="secondary">
			<>
				<FontAwesomeIcon
					icon={faFilePdf as IconProp}
					className="me-1 "
					style={{ marginRight: 5 }}
				/>
				{t('export.pdf')}
			</>
		</Button>
	);
};

ExportPdf.propTypes = {
	filename: PropTypes.string.isRequired,
};

export default ExportPdf;
