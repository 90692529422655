import axios from 'axios';

export const getProviders = (page, per_page, callback) => async () => {
	await axios
		.get(`provider?page=${page}&per_page=${per_page}`)
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};

export const linkStore =
	(store_id, store_provider_id, provider_id, provider_url, callback) =>
	async () => {
		await axios
			.post(`provider/store/${store_id}`, {
				store_provider_id: store_provider_id,
				provider_key: provider_id,
				provider_url: provider_url,
			})
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const unlinkStore = (store_id, callback) => async () => {
	await axios
		.delete(`provider/store/${store_id}`)
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};

export const getStoreProducts =
	(store_id, provider_id, callback) => async () => {
		await axios
			.get(`provider/store/products/${store_id}?provider=${provider_id}`)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const getStoreCollections =
	(store_id, provider_id, callback) => async () => {
		await axios
			.get(`provider/store/collections/${store_id}?provider=${provider_id}`)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const getProviderProducts =
	(provider, page, per_page, query, sort, callback) => async () => {
		await axios
			.get(`provider/products/${provider}?page=${page}&per_page=${per_page}&q=${query}&sort=${sort}`)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const linkProduct =
	(product_id, provider_store_id, provider_id, callback) => async () => {
		await axios
			.post(`provider/product/${product_id}`, {
				store_provider_id: provider_store_id,
				provider_key: provider_id,
				provider_url: '',
			})
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const unlinkProduct = (product_id, callback) => async () => {
	await axios
		.delete(`provider/product/${product_id}`)
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};

export const linkCollection =
	(collection_id, provider_store_id, provider_id, callback) => async () => {
		await axios
			.post(`provider/collection/${collection_id}`, {
				store_provider_id: provider_store_id,
				provider_key: provider_id,
				provider_url: '',
			})
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const unlinkCollection = (collection_id, callback) => async () => {
	await axios
		.delete(`provider/collection/${collection_id}`)
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};

export const uploadEntelData = (data, callback) => async () => {
	await axios
		.post(`provider/json/entel`, data)
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};

export const createProvider = (data, callback) => async () => {
	await axios
		.post(`provider`, data)
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};

export const updateProvider =
	(store_provider_id, data, callback) => async () => {
		await axios
			.put(`provider/${store_provider_id}`, data)
			.then((response) => {
				callback(response);
			})
			.catch((error) => {
				callback(error.response);
			});
	};

export const deleteProvider = (store_provider_id, callback) => async () => {
	await axios
		.delete(`provider/${store_provider_id}`)
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};
