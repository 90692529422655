import React, { useState } from 'react';
import SimpleBar from 'simplebar-react';
import { useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faBoxOpen,
	faChartPie,
	faHandHoldingUsd,
	faTimes,
	faStore,
	faHome,
	faClipboardList,
	faHeadset,
	faChalkboardTeacher,
	faListUl,
	faUsers,
	faQuestionCircle,
	faLayerGroup,
	faFileCsv,
	faEnvelope,
	faCloud,
	faLink,
	faWarehouse,
	faCube,
	faQuestion,
	faCubes,
	faStar,
	faShirt
} from '@fortawesome/free-solid-svg-icons';
import {
	Nav,
	Image,
	Button,
	Dropdown,
	Accordion,
	Navbar,
	Badge,
} from 'react-bootstrap';
import { JoyrideOnboard } from '../../components';
import { Link } from 'react-router-dom';
import { Routes } from '../../routes';
import ApparLogo from '../../assets/img/technologies/APP.png';
import { useTranslation } from 'react-i18next';
import jwtDecode from 'jwt-decode';
import { toast } from 'react-toastify';

const Sidebar = (props) => {
	const { t } = useTranslation();
	const location = useLocation();
	const { pathname } = location;
	const [show, setShow] = useState(false);
	const showClass = show ? 'show' : '';
	const decoded = localStorage.getItem('token')
		? jwtDecode(localStorage.getItem('token'))
		: null;

	const onCollapse = () => setShow(!show);





	const CollapsableNavItem = (props) => {
		const { eventKey, title, icon, children = null } = props;
		const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : '';

		return (
			<Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
				<Accordion.Item eventKey={eventKey} className="border-0 bg-transparent">
					<Accordion.Button
						as={Nav.Link}
						className="d-flex justify-content-between align-items-center  bg-primary"
					>
						<span>
							<span className="sidebar-icon">
								<FontAwesomeIcon icon={icon} />{' '}
							</span>
							<span className="sidebar-text">{title}</span>
						</span>
					</Accordion.Button>
					<Accordion.Body className="multi-level">
						<Nav className="flex-column">{children}</Nav>
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
		);
	};

	const NavItem = (props) => {
		const {
			title,
			link,
			external,
			target,
			icon,
			image,
			badgeText,
			badgeBg = 'danger',
			badgeColor = '#fff',
			onBadgeClick,
		} = props;
		const classNames = badgeText
			? 'd-flex justify-content-start align-items-center justify-content-between'
			: '';
		const navItemClassName =
			link === pathname
				? 'active ' + link.toString().split('/')[1]
				: link.toString().split('/')[1];

		const linkProps = external ? { href: link } : { as: Link, to: link };

		return (
			<Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
				<Nav.Link {...linkProps} target={target} className={classNames}>
					<span>
						{icon ? (
							<span className="sidebar-icon">
								<FontAwesomeIcon icon={icon} />{' '}
							</span>
						) : null}
						{image ? (
							<Image
								src={image}
								width={20}
								height={20}
								className="sidebar-icon svg-icon"
							/>
						) : null}

						<span className="sidebar-text">{title}</span>
					</span>
					{badgeText ? (
						<Badge
							pill
							bg={badgeBg}
							text={badgeColor}
							onClick={() => onBadgeClick()}
							className="badge-md notification-count ms-2"
						>
							{badgeText}
						</Badge>
					) : null}
				</Nav.Link>
			</Nav.Item>
		);
	};

	return (
		<>
			<Navbar
				expand={false}
				collapseOnSelect
				variant="dark"
				className="navbar-theme-primary px-4 d-md-none"
			>
				<Navbar.Brand
					className="me-lg-5"
					as={Link}
					to={Routes.Analytics.path}
				></Navbar.Brand>
				<Navbar.Toggle
					as={Button}
					aria-controls="main-navbar"
					onClick={onCollapse}
				>
					<span className="navbar-toggler-icon" />
				</Navbar.Toggle>
			</Navbar>
			<CSSTransition timeout={300} in={show} classNames="sidebar-transition">
				<SimpleBar
					className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}
				>
					<div className="sidebar-inner px-2 pt-3">
						<div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
							<div className="d-flex align-items-center"></div>
							<Nav.Link
								className="collapse-close d-md-none"
								onClick={onCollapse}
							>
								<FontAwesomeIcon icon={faTimes} />
							</Nav.Link>
						</div>
						<div className="text-center text-md-center mb-15 mt-md-0">
							<Image src={ApparLogo} className="image image-ms" />
							<Dropdown.Divider className="my-3 border-indigo" />
							<JoyrideOnboard
								isBtnVisible={false}
								isFirstTime={true}
								steps={[
									{
										content: t('components:joyride.sidebar_step_1'),
										target: '.pricing',
										placement: 'right',
										disableBeacon: true,
									},
									{
										content: t('components:joyride.sidebar_step_2'),
										target: '.store',
										placement: 'right',
										disableBeacon: true,
									},
									{
										content: t('components:joyride.sidebar_step_3'),
										target: '.product',
										placement: 'right',
										disableBeacon: true,
									},
									{
										content: (
											<div>
												<Button className="copilot-btn" variant="tertiary">
													<FontAwesomeIcon icon={faQuestion} className="mb-2" />
												</Button>
												<div className='mt-2'>
													{t('components:joyride.sidebar_step_4')}
												</div>

											</div>
										),
										target: '.product',
										placement: 'right',
									},
								]}
							/>


						</div>

						<Nav className="flex-column pt-3 pt-md-0">
							<p style={{ marginTop: 8, color: '#66799e' }}>{t('menu')}</p>
							{/* Basic */}
							<NavItem
								title={t('home')}
								link={Routes.Overview.path}
								icon={faHome}
							/>
							<NavItem
								title={t('statistics')}
								link={Routes.Analytics.path}
								icon={faChartPie}
							/>
							<NavItem
								title={t('categories')}
								link={Routes.Category.path}
								icon={faListUl}
							/>

							{decoded && decoded.user_type_id !== 3 && (
								<NavItem
									title={t('stores')}
									link={Routes.Store.path}
									icon={faStore}
									className="store"
								/>
							)}

							<NavItem
								title={t('products')}
								link={Routes.Products.path}
								icon={faBoxOpen}
								className="product"
							/>
							<NavItem
								title={t('collections')}
								link={Routes.Collection.path}
								icon={faLayerGroup}
								badgeText="Pro"
								onBadgeClick={() => toast.info(t('pro_message'))}
							/>

							{decoded && decoded.user_type_id !== 3 && (
								<NavItem
									title={t('users')}
									link={Routes.User.path}
									icon={faUsers}
								/>
							)}


							<p style={{ marginTop: 8, color: '#66799e' }}>{t('tools')}</p>

							<NavItem
								title={t('3dviewer')}
								link={Routes.ThreeDViewer.path}
								icon={faCube}
							/>
							<NavItem
								title={t('3deditor')}
								link={Routes.ThreeDEditor.path}
								icon={faCubes}
							/>
							{/* Integrations */}
							{decoded && decoded.user_type_id !== 3 && (
								<>
									<p style={{ marginTop: 8, color: '#66799e' }}>
										{t('integrations')}
									</p>
									<NavItem
										title={t('tiendanube')}
										link={Routes.TiendaNube.path}
										icon={faCloud}
									/>
									<NavItem
										title={t('multivende')}
										link={Routes.Multivende.path}
										image="/images/integrations/multivende.svg"
									/>
									{/* <NavItem title={t('vtex')} link={Routes.Vtex.path} icon={faCloud} />
                  					<NavItem title={t('shopify')} link={Routes.Shopify.path} icon={faShopify} /> */}
									<NavItem
										title={t('other')}
										link={Routes.Link.path}
										icon={faLink}
									/>
								</>
							)}

							{/* Payment */}
							{decoded && decoded.user_type_id !== 3 && (
								<>
									<p style={{ marginTop: 8, color: '#66799e' }}>
										{t('payments')}
									</p>
									<NavItem
										title={t('my_payments')}
										link={Routes.Payment.path}
										icon={faHandHoldingUsd}
									/>
									<NavItem
										title={t('plans')}
										link={Routes.Pricing.path}
										icon={faClipboardList}
										className="pricing"
									/>
								</>
							)}

							{/* Help */}
							<p style={{ marginTop: 8, color: '#66799e' }}>
								{t('help_and_support')}
							</p>
							<NavItem
								title={t('tutorial')}
								link={Routes.Tutorial.path}
								icon={faChalkboardTeacher}
							/>
							<NavItem
								title={t('technical_support')}
								link={Routes.Support.path}
								icon={faHeadset}
							/>
							<NavItem
								title={t('frequent_questions')}
								link={Routes.FrequentQuestions.path}
								icon={faQuestionCircle}
							/>

							{/* Admin */}
							{decoded && decoded.user_type_id === 1 && (
								<>
									<p style={{ marginTop: 8, color: '#66799e' }}>
										{t('administration')}
									</p>
									<CollapsableNavItem
										eventKey="1"
										title={t('user_options')}
										icon={faUsers}
									>
										<NavItem
											title={t('user_list_admin')}
											link={Routes.AdminUser.path}
										/>
										<NavItem
											title={t('payments')}
											link={Routes.AdminPayments.path}
										/>
									</CollapsableNavItem>
									<CollapsableNavItem
										eventKey="components/"
										title={t('store_options')}
										icon={faStore}
									>
										<NavItem
											title={t('stores')}
											link={Routes.AdminStore.path}
										/>
										<NavItem
											eventKey="stores"
											title={t('products')}
											link={Routes.AdminProducts.path}
										/>
										<NavItem
											eventKey="stores"
											title={t('statistics')}
											link={Routes.AdminAnalytics.path}
										/>
										<NavItem
											eventKey="stores"
											title={t('data_integrity')}
											link={Routes.AdminIntegrity.path}
										/>
									</CollapsableNavItem>

									<NavItem
										title={t('providers')}
										link={Routes.AdminProviders.path}
										icon={faWarehouse}
									/>
									<NavItem
										title={t('global_messages')}
										link={Routes.GlobalMessage.path}
										icon={faEnvelope}
									/>
									<NavItem
										title={t('reports')}
										link={Routes.Reports.path}
										icon={faFileCsv}
									/>
									<NavItem
										title={t('api_statistics')}
										link={Routes.AdminApiAnalytics.path}
										icon={faChartPie}
									/>
									<NavItem
										title={t('top_products')}
										link={Routes.AdminTopProducts.path}
										icon={faStar}
									/>
									{/* <NavItem
										title={t('teamwear')}
										link={Routes.AdminTeamWear.path}
										icon={faShirt}
									/> */}
								</>
							)}

							<Dropdown.Divider className="my-3 border-indigo" />
						</Nav>
					</div>
				</SimpleBar>
			</CSSTransition>

		</>
	);
};

export default Sidebar;
