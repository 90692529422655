import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { Col, Container, Row, Dropdown, DropdownButton } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ExportCsvGeneric, Navbar, SimplifiedCard, DatePicker } from '../../../components';
import { ReportRequests, AdminRequests } from '../../../requests';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
var moment = require('moment');

const Reports = () => {
	const { t } = useTranslation(['date', 'common', 'error']);
	const dispatch = useDispatch();
	const months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"]

	const [entelDates, setEntelDates] = useState({
		from: moment(moment().year() + "-" + (moment().month()) + "-" + "21", 'YYYY-MM-DD'),
		to: moment(moment().year() + "-" + (moment().month() + 1) + "-" + "20", 'YYYY-MM-DD')
	})

	const [dates, setDates] = useState({
		from: moment(moment().year() + "-" + (moment().month() + 1) + "-" + "1", 'YYYY-MM-DD'),
		to: moment(moment().year() + "-" + (moment().month() + 1) + "-" + moment().date(), 'YYYY-MM-DD')
	})

	const [selectedStore, setSelectedStore] = useState([])
	const [stores, setStores] = useState([])
	const [selectedMonth, setSelectedMonth] = useState("jan.")

	const entel_store_id = 90

	useEffect(() => {
		getStores()
		if (moment().month() + 1 === 0) {
			setSelectedMonth(months[0])
		} else {
			setSelectedMonth(months[moment().month()])
		}

	}, [])

	const getStores = () => {
		dispatch(
			AdminRequests.getAllStores(``, (response) => {
				if (response.status === 200) {
					setStores(response.data)
				} else {
					toast.error(t(`error:${response.data}`))
				}
			})
		)
	}

	const userReportHeaders = [
		{ label: 'Tienda', key: 'store' },
		{ label: 'Proveedor', key: 'providers' },
		{ label: 'SKUS disponibles', key: 'skus' },
		{ label: 'SKUS usados', key: 'used_skus' },
		{ label: 'Nombre', key: 'name' },
		{ label: 'Apellido', key: 'lastname' },
		{ label: 'ID de usuario', key: 'user_id' },
		{ label: 'Activo', key: 'active' },
		{
			label: 'Pago mas cercano a vencer (año-mes-dia)',
			key: 'payment_expiration',
		},
		{ label: 'Email', key: 'email' },
		{ label: 'Telefono empresa', key: 'company_phone' },
	];

	const paymentReportHeaders = [
		{ label: 'ID de usuario', key: 'user_id' },
		{ label: 'Nombre', key: 'name' },
		{ label: 'Apellido', key: 'lastname' },
		{ label: 'Email', key: 'email' },
		{ label: 'ID pago', key: 'payment_id' },
		{ label: 'ID estado de pago', key: 'payment_status_id' },
		{ label: 'Creación pago', key: 'created_at' },
		{ label: 'Vencimiento pago', key: 'expires_at' },
		{ label: 'SKUS disponibles', key: 'skus' },
		{ label: 'SKUS usados', key: 'used_skus' },
		{ label: 'Token', key: 'token' },
	];

	const entelReportHeaders = [
		{ label: 'Id producto', key: 'product_id' },
		{ label: 'Nombre producto', key: 'name' },
		{ label: 'Visitas', key: 'visits' }
	];

	const productReportHeaders = [
		{ label: 'Id producto', key: 'product_id' },
		{ label: 'Nombre producto', key: 'name' },
		{ label: 'Fecha creación', key: 'created_at' },
		{ label: 'Enlace a tienda', key: 'url' },
		{ label: 'Visitas', key: 'visits' },
		{ label: 'Aumentaciones', key: 'augmentations' }
	];

	const switchMonth = (e) => {
		if (moment().month() + 1 === 0) {
			setSelectedMonth(months[0])
		} else {
			setSelectedMonth(months[moment().month()])
		}

		if (e !== 0) {
			setSelectedMonth(months[e])
			setEntelDates({
				from: moment(moment().year() + "-" + (e) + "-" + "21", 'YYYY-MM-DD'),
				to: moment(moment().year() + "-" + (e + 1) + "-" + "20", 'YYYY-MM-DD')
			})
		} else {
			setSelectedMonth(months[0])
			setEntelDates({
				from: moment(moment().year() - 1 + "-" + 12 + "-" + "21", 'YYYY-MM-DD'),
				to: moment(moment().year() + "-" + (e + 1) + "-" + "20", 'YYYY-MM-DD')
			})
		}
	}

	return (
		<>
			<div>
				<Navbar title={t('common:reports')} iconTitle={faFileCsv} />
			</div>
			<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4"></div>
			<div>
				<SimplifiedCard>
					<Container>
						<Row>
							<Col xs={2}>{t('components:export.user_report')}</Col>
							<Col></Col>
							<Col xs={3}>
								<ExportCsvGeneric
									header={userReportHeaders}
									filename={'reporte-usuarios'}
									title={'user_report'}
									onClick={async () => {
										const data = await new Promise((resolve, reject) => {
											dispatch(
												ReportRequests.getUserReport((response) => {
													if (response.status == 200) {
														if (response.data.length == 0) {
															toast.success(t('components:export.no_data'));
														}
														resolve(response.data);
													} else {
														toast.error(`error:${response.data}`);
														reject();
													}
												})
											);
										});
										return data;
									}}
								/>
							</Col>
						</Row>

						<hr />

						<Row>
							<Col xs={2}>{t('components:export.payment_report')}</Col>
							<Col></Col>
							<Col xs={3}>
								<ExportCsvGeneric
									header={paymentReportHeaders}
									filename={'reporte-pagos'}
									title={'payment_report'}
									onClick={async () => {
										const data = await new Promise((resolve, reject) => {
											dispatch(
												ReportRequests.getPaymentReport((response) => {
													if (response.status == 200) {
														if (response.data.length == 0) {
															toast.success(t('components:export.no_data'));
														}
														resolve(response.data);
													} else {
														toast.error(`error:${response.data}`);
														reject();
													}
												})
											);
										});

										return data;
									}}
								/>
							</Col>
						</Row>

						<hr />

						<Row>
							<Col xs={2}>{t('components:export.entel_report')}</Col>
							<Col>
								<DropdownButton
									title={t(`date:month.${selectedMonth}.long`)}>
									{
										months.map((mon, i) => {
											return <Dropdown.Item key={i} onClick={(e) => { switchMonth(i) }} disabled={i > moment().month()}>{t(`date:month.${mon}.long`)}</Dropdown.Item>
										})
									}
								</DropdownButton>
							</Col>
							<Col xs={3}>
								<ExportCsvGeneric
									header={entelReportHeaders}
									filename={'reporte-entel-' + entelDates.from.format('YYYY-MM-DD') + "-a-" + entelDates.to.format('YYYY-MM-DD')}
									title={'entel_report'}
									onClick={async () => {
										const data = await new Promise((resolve, reject) => {
											dispatch(
												ReportRequests.getEntelReport("store_id=" + entel_store_id + "&from=" + entelDates.from.format('YYYY-MM-DD') + "&to=" + entelDates.to.format('YYYY-MM-DD'),
													(response) => {
														if (response.status == 200) {
															if (response.data.length == 0) {
																toast.success(t('components:export.no_data'));
															}
															resolve(response.data);
														} else {
															toast.error(`error:${response.data}`);
															reject();
														}
													})
											);
										});
										return data;
									}}
								/>
							</Col>
						</Row>

						<hr />

						<Row className="justify-content-md-center">
							<Col xs={2}>{t('components:export.product_report')}</Col>
							<Col xs={4}>
								<Typeahead
									id="stores-typeahead"
									labelKey={(option) => `${option.name} (${option.owner})`}
									options={stores}
									onChange={setSelectedStore}
									selected={selectedStore}
									placeholder={t('common:search') + " " + t('common:store')}
									emptyLabel={t('common:no_results')}
								/>
							</Col>
							<Col xs={3}>
								<DatePicker onDateChange={(e) => setDates(e)} />
							</Col>
							<Col xs={3}>
								<ExportCsvGeneric
									header={productReportHeaders}
									filename={selectedStore.length > 0 ? `reporte-productos-${dates.from.format('YYYY-MM-DD')}-a-${dates.to.format('YYYY-MM-DD')}-${selectedStore[0].name}-(${selectedStore[0].owner})` : ""}
									title={'product_report'}
									onClick={async () => {
										if (selectedStore.length > 0) {
											const data = await new Promise((resolve, reject) => {
												dispatch(
													ReportRequests.getProductReport("store_id=" + selectedStore[0].store_id + "&from=" + dates.from.format('YYYY-MM-DD') + "&to=" + dates.to.format('YYYY-MM-DD'),
														(response) => {
															if (response.status == 200) {
																if (response.data.length == 0) {
																	toast.success(t('components:export.no_data'))
																}
																resolve(response.data)
															} else {
																toast.error(t(`error:${response.data}`))
																reject()
															}
														})
												)
											})
											return data
										} else {
											toast.error(t(`error:store_required`))
											return []
										}
									}} />
							</Col>
						</Row>
					</Container>
				</SimplifiedCard>
			</div>
		</>
	)
}

export default Reports
