import { Form } from "react-bootstrap"
import proptypes from 'prop-types'
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import './style.css'

interface Props {
    characterLimit: number,
    onChange: (text: string) => void
    placeholder: string,
    required: boolean,
    sm: boolean,
    title: string,
    value: string,
}

const TextInput = (props: Props) => {
    const {
        characterLimit,
        onChange,
        placeholder,
        required,
        sm,
        title,
        value,
    } = props

    const { t } = useTranslation()

    const [text, setText] = useState(value ? value : "")
    const [textState, setTextState] = useState('normal')

    useEffect(() => {
        if (value && value.length > 0) {
            setTextState('success')
        }
    }, [])

    useEffect(() => {
        onChange(text)
    }, [text])

    return (
        <>
            {!sm && title.length > 0 &&
                <h5 className="my-4">{title}</h5>
            }
            <Form.Group>
                {sm && title.length > 0 &&
                    <Form.Label className="col-form-label">
                        {title}
                    </Form.Label>
                }

                <Form.Control
                    type="text"
                    placeholder={placeholder}
                    value={text}
                    onChange={(e) => {
                        setText(
                            characterLimit > 0 ?
                                e.target.value.substring(0, characterLimit) 
                                :
                                e.target.value
                        )
                        switch (true) {
                            case e.target.value.length > 0:
                                setTextState('success')
                                break
                            case e.target.value.length === 0:
                                setTextState('error')
                                break
                            default:
                                setTextState('normal')
                                break
                        }
                    }}
                />
                {required &&
                    <Form.Text className={`${textState}-text`}>
                        {t('common:required_field')}
                    </Form.Text>
                }
                {characterLimit > 0 &&
                    <Form.Text id="passwordHelpBlock" muted className="character-count-text">
                        {t('common:character_count', { current: text.length, total: characterLimit })}
                    </Form.Text>
                }
            </Form.Group>
        </>
    )
}

TextInput.propTypes = {
    characterLimit: proptypes.number,
    onChange: proptypes.func,
    placeholder: proptypes.string,
    required: proptypes.bool,
    title: proptypes.string,
    value: proptypes.string,
    sm: proptypes.bool
}

TextInput.defaultProps = {
    characterLimit: 0,
    onChange: () => { },
    placeholder: "",
    required: false,
    title: "",
    value: "",
    sm: false
}

export default TextInput