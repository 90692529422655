import {
	faCheckCircle,
	faQuestionCircle,
	faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import propTypes from 'prop-types';
import { Tooltip } from '..';
import FileSaver from 'file-saver';

const Dropozone = (props) => {
	const {
		accept,
		activeTooltip,
		file,
		fileTooHeavyMessage,
		id,
		instructionMessage,
		maxFileSize,
		multiple,
		onFileChange,
		onFileDelete,
		title,
		tooltipMessage,
		tooltipTitle,
	} = props;

	const { t } = useTranslation('components');

	return (
		<>
			<Container
				border="light"
				className=" shadow-sm mb-4 "
				style={{ alignItems: 'center' }}
			>
				{title && (
					<h5 className="text-center my-3">
						{`${title} `}
						{activeTooltip && (
							<>
								<Tooltip
									title={tooltipTitle}
									content={tooltipMessage}
									trigger="click"
									placement="right"
								>
									<FontAwesomeIcon
										icon={faQuestionCircle}
										style={{ fontSize: 18, color: '#65AF1A' }}
									/>
								</Tooltip>
							</>
						)}
					</h5>
				)}

				<div className="wrapper">
					<div className="file-upload ">
						<input
							type="file"
							name="files"
							id={id}
							accept={accept}
							message=""
							onChange={(e) => {
								if (e.target.files.length > 0) {
									if (e.target.files[0].size <= maxFileSize) {
										onFileChange(
											e.target.files[0],
											URL.createObjectURL(e.target.files[0])
										);
									} else {
										toast.error(fileTooHeavyMessage);
									}
								} else {
									onFileDelete();
								}
							}}
						/>
						<i className="fas fa-cloud-upload-alt"></i>
					</div>
				</div>
				{file ? (
					<div className="text-center">
						<p className="name-input">
							<FontAwesomeIcon
								icon={faCheckCircle}
								style={{
									fontSize: 20,
									color: '#008F39',
									marginRight: 5,
								}}
							/>
							{file.name
								? file.name
								: file.split('/')[file.split('/').length - 1]}
							<FontAwesomeIcon
								onClick={(e) => {
									onFileChange(null, '');
									document.querySelector(`#${id}`).value = '';
									onFileDelete();
								}}
								icon={faTrashAlt}
								style={{
									fontSize: 18,
									color: '#CA0B00',
									marginLeft: 10,
								}}
							/>
						</p>
					</div>
				) : (
					<div className="text-center">
						<p className="mt-3">{instructionMessage}</p>
						<p className="mt-3">
							{t('dropzone.maximum_file_weight', {
								maxSize: maxFileSize / 1000000,
							})}
						</p>
					</div>
				)}
			</Container>
		</>
	);
};

Dropozone.propTypes = {
	accept: propTypes.string,
	activeTooltip: propTypes.bool,
	file: propTypes.object,
	fileTooHeavyMessage: propTypes.string,
	id: propTypes.string,
	instructionMessage: propTypes.string,
	maxFileSize: propTypes.number,
	onFileChange: propTypes.func.isRequired,
	onFileDelete: propTypes.func,
	title: propTypes.string,
	tooltipMessage: propTypes.string,
	tooltipTitle: propTypes.string,
};

Dropozone.defaultProps = {
	maxFileSize: 20000000,
	accept: '.glb, .gltf',
	tooltipTitle: '',
};

export default Dropozone;
