import axios from 'axios';
import { Collection, RequestCallback } from 'typings/custom';

export const createCollection = (collection: Collection, callback: RequestCallback) => async () => {
	await axios
		.post('/collection', collection)
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};

export const deleteCollection = (id: ID, callback: RequestCallback) => async () => {
	await axios
		.delete(`/collection/${id}`)
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};

export const updateCollection = (collection: Collection, callback: RequestCallback) => async () => {
	await axios
		.put(`/collection/${collection.collection_id}`, collection)
		.then((response) => {
			callback(response);
		})
		.catch((error) => {
			callback(error.response);
		});
};
