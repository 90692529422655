import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';

const SimplifiedCard = (props) => {
	const { headerType, headerContent, children } = props;

	const generateTitle = () => {
		var title = <Card.Title>{headerContent}</Card.Title>;

		switch (headerType) {
			case 'subtitle':
				title = <Card.Subtitle>{headerContent}</Card.Subtitle>;
				break;
			case 'text':
				title = <Card.Text>{headerContent}</Card.Text>;
				break;
		}

		return title;
	};

	return (
		<Card>
			{headerContent && headerContent.length > 0 && (
				<Card.Header>{generateTitle()}</Card.Header>
			)}
			<Card.Body>{children}</Card.Body>
		</Card>
	);
};

SimplifiedCard.propTypes = {
	headerContent: PropTypes.string,
	headerType: PropTypes.oneOf(['title', 'subtitle', 'text']),
};
SimplifiedCard.defaultProps = {
	headerContent: null,
};

export default SimplifiedCard;
